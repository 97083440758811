//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const german = Object.freeze({
    and:                           'und',
    more:                          'Mehr',
    no:                            'Nein',
    oclock:                        'Uhr',
    or:                            'oder',
    pageTitle:                     'bodo-transit',
    versionNumberDateFormat:       'DD.MM.YYYY HH:mm:ss',
    yes:                           'Ja',
    imprintUrl:                    'https://www.bodo.de/impressum.html',
    imprint:                       'Impressum',
    logout:                        'Logout',
    ascendant:                     'Aufsteigend',
    descendant:                    'Absteigend',
    stagingLogin:                  'Willkommen auf bodo-transit. Bitte geben Sie hier das Passwort für das Testsystem ein',
    minutesShort:                  'Min.',
    components:                    {
        sidebar:    {
            visibleElements:        'Sichtbare Elemente',
            favorites:              'Favoriten',
            lines:                  'Linien',
            busStops:               'Bus-Haltestellen',
            trainStations:          'Zug-Haltestellen',
            passengerVolume:        'Fahrgastaufkommen',
            disturbances:           'Störungen',
            constructionSites:      'Baustellen',
            delays:                 'Verspätungen',
            connectionNotReachable: 'Anschluss nicht erreichbar',
            connectionCombinations: 'Verbindungskombinationen',
            weather:                'Wetter',
            information:            'Informationen',
        },
        search:     {
            search:                  'Suche',
            stops:                   'Haltestellen',
            routes:                  'Verbindungen',
            stopsAndRoutes:          'Verbindungen/Haltestellen',
            searchStopLabel:         'Haltestelle/Ort',
            searchRouteFromLabel:    'Von',
            searchRouteToLabel:      'Nach',
            searchStopButtonText:    'Suchen',
            searchRouteButtonText:   'Verbindung finden',
            overview:                'Übersicht',
            connection:              'Verbindung',
            people:                  'Personen',
            punctualityDelay:        '{{percentage}}% Verspätet',
            punctualityNotReachable: 'Nicht erreichbar',
            punctualityOnTime:       'Pünktlich',
            unknown:                 'Unbekannt',
            noResults:               'Es wurden keine Verbindungen gefunden.',
            error:                   'Es ist ein Fehler bei der Suche aufgetreten. Bitte versuchen Sie es später erneut.',
        },
        action:     {
            quickView:          'Schnelle Ansicht',
            mostTransits:       'Die meisten Umstiege',
            leastTransits:      'Die wenigsten Umstiege',
            reliableTransits:   'Zuverlässigste Umstiege',
            unreliableTransits: 'Unzuverlässigste Umstiege',
            focalPoints:        'Wo brennt\'s Aktuell',
            noResults:          'Es wurden keine Ergebnisse gefunden.',
            connectionInfo:     'Durchschnittlich',
            error:              'Es ist ein Fehler bei der Abfrage aufgetreten. Bitte versuchen Sie es später erneut.',
        },
        stop:       {
            overview:     'Übersicht',
            weather:      'Wetter',
            connections:  'Verbindungen',
            punctuality:  'Pünktlichkeit',
            volume:       'Aufkommen',
            reachability: 'Erreichbarkeit',
        },
        login:      {
            email:             'E-Mail Adresse',
            password:          'Passwort',
            login:             'Login',
            autoLogin:         'Auto-Login',
            passwordForgotten: 'Passwort vergessen?',
            newPassword:       'Neues Passwort',
            imprint:           'Impressum',
        },
        datePicker: {
            today:                'Heute',
            save:                 'Speichern',
            delete:               'Löschen',
            from:                 'Von',
            to:                   'Bis',
            calendarLegendHigh:   'Hohe Datenmenge',
            calendarLegendMedium: 'Moderate Datenmenge',
            calendarLegendLow:    'Geringe Datenmenge',
        },
        context:    {
            averagePassengerVolumeStop:  'In der angegebenen Zeitspanne haben <strong>{{count, number}}</strong> Fahrgäste den Halt passiert',
            averagePassengerVolumeRoute: 'In der angegebenen Zeitspanne haben durchschnittlich <strong>{{count, number}}</strong> Fahrgäste die Verbindung genutzt',
            selectLine:                  'Linie auswählen',
            noDataLabel:                 'Leider stehen zu wenig Daten zur Verfügung, um diese Ansicht zu füllen.',
            connectionsCombinations:     'Verbindungskombinationen',
            averageAllConnections:       'Durchschnitt alle Fahrten',
            transferred:                 'Umgestiegen',
            lowUsage:                    'Geringe Nutzung',
            mediumUsage:                 'Mittlere Nutzung',
            highUsage:                   'Hohe Nutzung',
            passengerVolume:             'Fahrgastaufkommen',
            usageTotal:                  'Aufkommen',
            usageEntered:                'Zugestiegen',
            usageExited:                 'Ausgestiegen',
            punctuality:                 'Pünktlichkeit',
            slotLabelHour:               'Am {{date}} um {{time}} Uhr',
            slotLabelDay:                'Am {{date}}',
            slotLabelMonth:              'Im {{monthAndYear}}',
            rides:                       'Fahrten',
            delay:                       'Verspätung',
            percentualDelay:             '({{percentage}}%)',
        },
    },
    alertBoxErrorText:             'Fehler',
    alertBoxErrorTextTranslated:   'Fehler (automatisch übersetzt)',
    alertBoxSuccessText:           'Erfolg',
    alertBoxSuccessTextTranslated: 'Erfolg (automatisch übersetzt)',
    autoLogin:                     'Auto Login',
    back:                          'Zurück',
    countDownDay:                  'Tag',
    countDownDay_plural:           'Tage',
    countDownHour:                 'Stunde',
    countDownHours:                'Stunden',
    countDownMinute:               'Minute',
    countDownMinutes:              'Minuten',
    countDownSecond:               'Sekunde',
    countDownSeconds:              'Sekunden',
    decimalSeparator:              ',',
    error_404:                     'Du stehst am falschen Gleis!',
    euroSign:                      '€',
    backToMap:                     'Zurück zur Karte',
    pageHome:                      'Karte',
    pageLogin:                     'Login',
    iAmScrolled:                   'Ich scrolle mit',
    inventory:                     'Inventar',
    inventoryDetails:              'Inventar-Details',
    inventoryHasChanged:           'Das Inventar wurde geändert: {{result}}',
    itemNotFound:                  'Eintrag nicht gefunden',
    less:                          'Weniger',
    loading:                       'Lade...',
    loadingConfiguration:          'Lade Server-Konfiguration...',
    loadingInventory:              'Lade Inventarliste...',
    loadingLogin:                  'Prüfe Zugangsdaten...',
    loadingTranslations:           'Lade Übersetzungen...',
    login:                         'Login',
    markupTranslationExample:      'Die foo ist ein <strong>{{bar}}</strong>',
    newVersionHintLaterButton:     'Later',
    newVersionHintText:            'A new version of this application is available, please update now.',
    newVersionHintUpdateButton:    'Update',
    pageNotFound:                  'Seite nicht gefunden',
    notFoundText:                  'Die angeforderte Seite wurde nicht gefunden.',
    rangeCombinerFrom:             'von',
    rangeCombinerShort:            ' - ',
    rangeCombinerTo:               'bis',
    reloadData:                    'Daten neu laden',
    repeatedText:                  'Dieser Text wurde {{iterationCount}} mal wiederholt.',
    screenTooSmallText:            'Ihre aktuelle Browsergröße wird derzeit nicht unterstützt. Bitte vergrößern Sie dieses Fenster.',
    screenTooSmallTitle:           'Ihr Display oder Browserfenster ist zu klein',
    shuffleList:                   'Liste mischen',
    smartUpdater:                  'Smart Updater Debugger',
    smartUpdaterData:              'Daten',
    smartUpdaterLastUpdate:        'Zuletzt aktualisiert',
    smartUpdaterReload:            'neu laden',
    smartUpdaterReloadAll:         'Alle Daten neu laden',
    smartUpdaterReset:             'zurücksetzen',
    smartUpdaterResetAll:          'Smart Updater zurücksetzen',
    stagingPasswordPlaceholder:    'Staging-Passwort',
    thousandSeparator:             '.',
    webAppCancel:                  'Abbrechen',
    webAppInstall:                 'Installieren',
    webAppInstallText:             'Sie können diese Anwendung installieren, um sie dauerhaft offline verwenden zu können.',
    webAppOk:                      'OK',
    windowSize:                    'Fenstergröße',
    notifications:                 {
        error:                'Fehler',
        info:                 'Info',
        success:              'Erfolg',
        warning:              'Warnung',
        wrongStagingPassword: 'Falsches Staging-Passwort',
        loginError:           'Der Login ist leider fehlgeschlagen. Bitte überprüfen Sie Ihre Zugangsdaten und versuchen es erneut.',
    },
});

export default german;
