//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useRef }    from 'react';
import { useMemo }   from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import classNames         from 'classnames';
import I18n               from 'i18next';
import _                  from 'lodash';
import { useTranslation } from 'react-i18next';

import PropTypes            from '@components/PropTypes';
import ConnectionDetailType from '@constants/ConnectionDetailType';
import Icon                 from '@stateless/atomic/Icon';
import IconColor            from '@stateless/atomic/Icon/IconColor';
import IconType             from '@stateless/atomic/Icon/IconType';
import ShortNameLabel       from '@stateless/atomic/ShortNameLabel';

import styles from './styles.module.scss';

const CHANGE_ICON_WIDTH = 20;

const propTypes = {
    connectionDetailType: PropTypes.oneOfObjectValues(ConnectionDetailType),
    fromStopName:         PropTypes.string,
    onItemClicked:        PropTypes.func,
    percentageDelay:      PropTypes.number,
    routes:               PropTypes.array,
    toStopName:           PropTypes.string,
};

const SidebarConnectionItem = ({
    fromStopName         = '',
    routes               = [],
    toStopName           = '',
    onItemClicked        = _.noop,
    connectionDetailType = null,
    percentageDelay      = 0,
}) => {
    const routesWrapperRef                = useRef(null);
    const { t }                           = useTranslation(null, {
        keyPrefix: 'components.search',
    });
    const showWithDetails                 = useMemo(() => {
        return connectionDetailType !== null;
    }, [connectionDetailType]);
    const [wrapperWidth, setWrapperWidth] = useState(0);

    useEffect(() => {
        if (routesWrapperRef.current) {
            setWrapperWidth(routesWrapperRef.current.clientWidth);
        }
    }, []);

    function renderRouteRealNumber(number, realNumber, postfix) {
        if (
            !realNumber ||
            number === realNumber
        ) {
            return null;
        }

        const differenceIsPositive = realNumber < number;

        return (
            <span
                className={classNames(
                    styles.detailsText,
                    {
                        [styles.positive]: differenceIsPositive,
                        [styles.negative]: !differenceIsPositive,
                    },
                )}
            >
                {realNumber} {postfix}
            </span>
        );
    }

    function renderChangeDetail(changeDetailNumber, changeDetailRealNumber) {
        if (!showWithDetails) {
            return null;
        }

        const iconType      = connectionDetailType === ConnectionDetailType.changeTime
            ? IconType.clock
            : IconType.passengerVolume;
        const detailPostfix = connectionDetailType === ConnectionDetailType.changeTime
            ? I18n.t('minutesShort')
            : null;

        return (
            <div
                className={styles.changeDetails}
            >
                <Icon
                    iconType={iconType}
                    iconColor={IconColor.gray}
                />
                <span className={styles.detailsText}>
                    {changeDetailNumber} {detailPostfix}
                </span>
                {renderRouteRealNumber(changeDetailNumber, changeDetailRealNumber, detailPostfix)}
            </div>
        );
    }

    function renderRouteChange(isLastRoute, route) {
        const { changeStopName, changeDetailNumber, changeDetailRealNumber } = route;

        if (isLastRoute) {
            return null;
        }

        const changeIconStyle = {
            width: CHANGE_ICON_WIDTH,
        };

        return (
            <div
                className={styles.routeChangeIcon}
                style={changeIconStyle}
            >
                {renderChangeDetail(changeDetailNumber, changeDetailRealNumber)}
                <Icon
                    iconType={IconType.change}
                    iconColor={IconColor.gray}
                />
                <div className={styles.changeStopName}>
                    {changeStopName}
                </div>
            </div>
        );
    }

    const renderRoutesLabels = () => {
        return routes.map((route, index) => {
            if (!wrapperWidth) {
                return null;
            }

            const { percentageWidth, name, color, onRouteClicked } = route;
            // @formatter:off because it's a calculation
            const fixedWidth                       = ((wrapperWidth - (CHANGE_ICON_WIDTH * (routes.length - 1))) / 100) * percentageWidth;
            // @formatter:on
            const isLastRoute                                      = index === routes.length - 1;

            return (
                <>
                    <ShortNameLabel
                        key={name}
                        fixedWidth={fixedWidth}
                        text={name}
                        color={color}
                        onLabelClicked={onRouteClicked}
                    />
                    {renderRouteChange(isLastRoute, route)}
                </>
            );
        });
    };

    function renderPunctualityLabel() {
        if (!showWithDetails) {
            return null;
        }

        let text = t('punctualityDelay', {
            percentage: percentageDelay,
        });

        if (percentageDelay >= 100) {
            text = t('punctualityNotReachable');
        } else if (percentageDelay <= 0) {
            text = t('punctualityOnTime');
        }

        return (
            <div className={styles.punctualityLabel}>
                {text}
            </div>
        );
    }

    return (
        <div
            className={styles.sidebarConnectionItem}
            onClick={onItemClicked}
        >
            <div className={styles.routeDetailsRow}>
                <div className={styles.routeInformation}>
                    <div
                        ref={routesWrapperRef}
                        className={styles.routesWrapper}
                    >
                        {renderPunctualityLabel()}
                        {renderRoutesLabels()}
                    </div>
                    <div
                        className={classNames(
                            styles.fromToLabel,
                            {
                                [styles.withDetails]: showWithDetails,
                            },
                        )}
                    >
                        <span>
                            {fromStopName}
                        </span>
                        <span>
                            {toStopName}
                        </span>
                    </div>
                </div>
                <Icon
                    iconType={IconType.chevronRight}
                    iconColor={IconColor.gray}
                />
            </div>
        </div>
    );
};

SidebarConnectionItem.propTypes = propTypes;

export default SidebarConnectionItem;
