//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                  from 'lodash';
import { useTranslation } from 'react-i18next';

import PropTypes             from '@components/PropTypes';
import ContextInformationTab from '@connected/ContextInformationPanel/ContextInformationTab';
import IconType              from '@stateless/atomic/Icon/IconType';
import TabBarButton          from '@stateless/atomic/TabBarButton';

import styles from './styles.module.scss';

const propTypes = {
    activeTab:          PropTypes.oneOfObjectKeys(ContextInformationTab),
    createOnTabClicked: PropTypes.func,
};

const ContextInformationTabBar = ({
    activeTab          = false,
    createOnTabClicked = _.noop,
}) => {
    const { t }             = useTranslation(null, {
        keyPrefix: 'components.stop',
    });
    const tabBarButtonItems = [
        {
            tab:      ContextInformationTab.overview,
            iconType: IconType.train,
        },
        {
            tab:      ContextInformationTab.weather,
            iconType: IconType.cloud,
            disabled: true,
        },
        {
            tab:      ContextInformationTab.connections,
            iconType: IconType.connections,
        },
        {
            tab:      ContextInformationTab.punctuality,
            iconType: IconType.punctuality,
        },
        {
            tab:      ContextInformationTab.volume,
            iconType: IconType.passengerVolume,
        },
        {
            tab:      ContextInformationTab.reachability,
            iconType: IconType.line,
            disabled: true,
        },
    ];

    function renderTabBarButtons() {
        return _.map(tabBarButtonItems, (item) => {
            const { tab, iconType } = item;
            const isActive          = activeTab === tab;

            return (
                <TabBarButton
                    disabled={item.disabled}
                    key={tab}
                    iconType={iconType}
                    text={t(tab)}
                    active={isActive}
                    onClick={createOnTabClicked(tab)}
                />
            );
        });
    }

    return (
        <div className={styles.contextInformationTabBarComponent}>
            {renderTabBarButtons()}
        </div>
    );
};

ContextInformationTabBar.propTypes = propTypes;

export default ContextInformationTabBar;
