//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import classNames         from 'classnames';
import I18n               from 'i18next';
import { uniqueId }       from 'lodash';
import _                  from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector }    from 'react-redux';

import PropTypes               from '@components/PropTypes';
import DateRangeHelper         from '@helper/DateRange';
import DateTime                from '@helper/DateTime';
import NumberFormat            from '@helper/NumberFormat';
import withContextPanelPadding from '@hoc/ContextPanelPadding';
import Icon                    from '@stateless/atomic/Icon';
import IconColor               from '@stateless/atomic/Icon/IconColor';
import IconType                from '@stateless/atomic/Icon/IconType';
import IconButton              from '@stateless/atomic/IconButton';
import ShortNameLabel          from '@stateless/atomic/ShortNameLabel';
import SmallCircle             from '@stateless/atomic/SmallCircle';
import UppercaseLabel          from '@stateless/atomic/UppercaseLabel';
import Colors                  from '@styles/colors.scss';

import styles from './styles.module.scss';

const propTypes = {
    connection: PropTypes.object,
    usage:      PropTypes.object,
};

const usageLevels = [
    {
        textKey:             'highUsage',
        color:               Colors.blue,
        thresholdDailyUsage: 40,
    },
    {
        textKey:             'mediumUsage',
        color:               Colors.yellow,
        thresholdDailyUsage: 20,
    },
    {
        textKey:             'lowUsage',
        color:               Colors.green,
        thresholdDailyUsage: 0,
    },
];

const ContextConnectionItem = ({
    connection = null,
    usage      = null,
}) => {
    const contextItemId                       = uniqueId('context_connection_item');
    const connections                         = connection?.connection ?? [];
    const transitTimes                        = connection?.transitTimes ?? [];
    const selectedDateRange                   = useSelector((state) => state.map.selectedDateRange);
    const [detailsVisible, setDetailsVisible] = useState(false);
    const { t }                               = useTranslation(null, {
        keyPrefix: 'components.context',
    });
    const renderTransitStop                   = (stopId, stopName) => {
        const transitItem         = transitTimes.find((transit) => transit.transitStop === stopId);
        const expectedTransitTime = _.get(transitItem, 'expectedTransitTime', null);
        const actualTransitTime   = _.get(transitItem, 'actualTransitTime', null);

        if (!transitItem) {
            return null;
        }

        const actualTransitTimeInMinutes       = DateTime.durationStringToMinutes(expectedTransitTime);
        const delayTransitTime                 = DateTime.getTwoHHMMSSDifference(expectedTransitTime, actualTransitTime);
        const actualTransitTimeInMinutesString = `${actualTransitTimeInMinutes} ${I18n.t('minutesShort')}`;
        const delayPrefix                      = NumberFormat.getNumberOperator(delayTransitTime);
        const delayString                      = `${delayPrefix}${Math.abs(delayTransitTime)} ${I18n.t('minutesShort')}`;

        return (
            <div className={styles.transitWrapper}>
                <div className={styles.transitTimeLabel}>
                    <Icon
                        iconType={IconType.change}
                        iconColor={IconColor.gray}
                    />
                    {actualTransitTimeInMinutesString}

                </div>
                <ShortNameLabel
                    key={`${contextItemId}_${stopId}`}
                    text={stopName}
                />
                <div className={styles.delayTimeLabel}>
                    {delayString}
                </div>
            </div>
        );
    };
    const renderConnectionLabels              = () => {
        return connections.map((connectionItem, index) => {
            const key      = `${contextItemId}_${index}`;
            const { line } = connectionItem;

            return (
                <>
                    <ShortNameLabel
                        key={key}
                        text={line}
                        color={Colors.black}
                        textColor={Colors.white}
                    />
                    {renderTransitStop(connectionItem.to.id, connectionItem.to.nameWithoutLocation)}
                </>
            );
        });
    };
    const renderFromToLabel                   = () => {
        const firstConnection = _.first(connections);
        const firstStopName   = firstConnection?.from?.nameWithoutLocation;
        const lastConnection  = _.last(connections);
        const lastStopName    = lastConnection?.to?.nameWithoutLocation;

        if (
            !firstConnection ||
            !lastConnection
        ) {
            return null;
        }

        return (
            <div className={styles.fromToLabel}>
                <span>
                    {firstStopName}
                </span>
                <span>
                    {lastStopName}
                </span>
            </div>
        );
    };
    const onDetailsToggleClicked              = () => {
        setDetailsVisible(!detailsVisible);
    };
    const renderConnectionDetails             = () => {
        const toggleIconType = detailsVisible
            ? IconType.chevronDown
            : IconType.chevronUp;
        const totalUsage     = usage?.total ?? 0;
        const dailyUsage     = totalUsage / DateRangeHelper.getDateRangeNumberOfDays(selectedDateRange);
        const usageLevel     = usageLevels.find((level) => dailyUsage >= level.thresholdDailyUsage);

        return (
            <div className={styles.connectionDetailsWrapper}>
                <div className={styles.toggleWrapper}>
                    <IconButton
                        iconType={toggleIconType}
                        onIconButtonClicked={onDetailsToggleClicked}
                    />
                </div>
                <div
                    className={classNames(
                        styles.connectionDetails,
                        {
                            [styles.visible]: detailsVisible,
                        },
                    )}
                >
                    <UppercaseLabel text={'Aufkommen'} />
                    <div className={styles.usageRow}>
                        <div className={styles.usageItem}>
                            <Icon iconType={IconType.passengerVolume} />
                            <span>
                                {usage?.total}
                            </span>
                            <span>
                                {t('transferred')}
                            </span>
                        </div>
                        <div
                            className={classNames(
                                styles.usageItem,
                                styles.bold,
                            )}
                        >
                            <SmallCircle color={usageLevel?.color} />
                            <span>
                                {t(usageLevel?.textKey)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return withContextPanelPadding(<div
        className={classNames(
            styles.contextConnectionItem,
            {
                [styles.detailsVisible]: detailsVisible,
            },
        )}
    >
        <div className={styles.connectionLabels}>
            {renderConnectionLabels()}
            {renderFromToLabel()}
        </div>
        {renderConnectionDetails()}
        <div className={styles.separator} />
    </div>);
};

ContextConnectionItem.propTypes = propTypes;

export default ContextConnectionItem;
