//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes     from '@components/PropTypes';
import TextInput     from '@stateless/atomic/TextInput';
import TextInputType from '@stateless/atomic/TextInput/TextInputType';

import styles from './styles.module.scss';

const propTypes = {
    labelText: PropTypes.string,
    onChange:  PropTypes.func,
    type:      PropTypes.string,
    value:     PropTypes.string,
};

const LabeledTextInput = ({
    labelText = '',
    value     = '',
    onChange  = _.noop,
    type      = TextInputType.text,
}) => {
    return (
        <div className={styles.labeledTextInputComponent}>
            <label>
                {labelText}
            </label>
            <TextInput
                value={value}
                onChange={onChange}
                type={type}
            />
        </div>
    );
};

LabeledTextInput.propTypes = propTypes;

export default LabeledTextInput;
