//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useState } from 'react';
import { useMemo }  from 'react';

import { useSelector } from 'react-redux';

import DateRangeHelper         from '@helper/DateRange';
import Distance                from '@helper/Distance';
import { useFetchRegionQuery } from '@store/api/region';

const EARTH_CIRCUMFERENCE_METERS = 40075016.686;

const APPROXIMATE_MAP_WIDTH = 1000;

const MAX_FETCH_ZOOM_LEVEL = 10;

const useRegionApi = () => {
    const zoom                                          = useSelector((state) => state.map.zoom);
    const coordinates                                   = useSelector((state) => state.map.coordinates);
    const bounds                                        = useSelector((state) => state.map.bounds);
    const selectedDateRange                             = useSelector((state) => state.map.selectedDateRange);
    const [lastFetchLongitude, setLastFetchLongitude]   = useState(0);
    const [lastFetchLatitude, setLastFetchLatitude]     = useState(0);
    const lastFetchingCoordinatesExist                  = (
        lastFetchLongitude !== 0 &&
        lastFetchLatitude !== 0
    );
    const distanceToLastFetchCoordinates                = Distance.calculateDistanceInMeterBetweenTwoCoordinates(
        lastFetchLatitude,
        lastFetchLongitude,
        coordinates.latitude,
        coordinates.longitude,
    );
    const zoomRadiusInMeter                             = useMemo(() => {
        const metersPerPixel = EARTH_CIRCUMFERENCE_METERS / (
            256 * 2 ** zoom
        );
        const widthInMeters  = APPROXIMATE_MAP_WIDTH * metersPerPixel;
        const radiusInMeters = widthInMeters / 2;

        return Math.round(radiusInMeters);
    }, [zoom]);
    const fetchingDistanceThreshold                     = zoomRadiusInMeter / 2;
    const distanceToLastFetchCoordinatesExceedThreshold = distanceToLastFetchCoordinates > fetchingDistanceThreshold;
    const dateRangeString                               = DateRangeHelper.getRangeString(selectedDateRange);
    const options                                       = {
        radiusInMeters: zoomRadiusInMeter,
        latitude:       coordinates.latitude,
        longitude:      coordinates.longitude,
        bounds:         bounds?.join(','),
        dateRange:      dateRangeString,
    };

    if (
        lastFetchingCoordinatesExist &&
        !distanceToLastFetchCoordinatesExceedThreshold
    ) {
        options.latitude  = lastFetchLatitude;
        options.longitude = lastFetchLongitude;
    }

    if (
        options.latitude !== lastFetchLatitude ||
        options.longitude !== lastFetchLongitude
    ) {
        setLastFetchLatitude(options.latitude);
        setLastFetchLongitude(options.longitude);
    }

    const zoomLevelIsAboveFetchLimit             = zoom < MAX_FETCH_ZOOM_LEVEL;
    const boundsAreNotSet                        = !bounds?.length;
    const shouldSkipThisQuery                    = (
        zoomLevelIsAboveFetchLimit ||
        boundsAreNotSet
    );
    const { data, error, isLoading, isFetching } = useFetchRegionQuery(options, {
        skip: shouldSkipThisQuery,
    });
    const stops                                  = zoomLevelIsAboveFetchLimit
        ? []
        : data?.stops;
    const routes                                 = zoomLevelIsAboveFetchLimit
        ? []
        : data?.routes;

    return {
        stops,
        routes,
        error,
        isLoading,
        isFetching,
    };
};

export default useRegionApi;
