//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { connectRouter }      from 'connected-react-router';
import { combineReducers }    from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

import { HistoryReducer }                from '@slices/history';
import { LoadingReducer }                from '@slices/loading';
import { MapReducer }                    from '@slices/map';
import { QuickViewReducer }              from '@slices/quickView';
import { SearchReducer }                 from '@slices/search';
import { StagingAuthenticationReducer }  from '@slices/stagingAuthentication';
import { UserReducer }                   from '@slices/user';
import { WindowReducer }                 from '@slices/window';
import { CalendarApiReducer }            from '@store/api/calendar';
import { CalendarApiReducerPath }        from '@store/api/calendar';
import { ConnectionsViewApiReducerPath } from '@store/api/connectionsView';
import { ConnectionsViewApiReducer }     from '@store/api/connectionsView';
import { ContextApiReducerPath }         from '@store/api/context';
import { ContextApiReducer }             from '@store/api/context';
import { PolylineApiReducerPath }        from '@store/api/polyline';
import { PolylineApiReducer }            from '@store/api/polyline';
import { RegionApiReducerPath }          from '@store/api/region';
import { RegionApiReducer }              from '@store/api/region';
import { RoutesApiReducerPath }          from '@store/api/routes';
import { RoutesApiReducer }              from '@store/api/routes';
import { StopsApiReducerPath }           from '@store/api/stops';
import { StopsApiReducer }               from '@store/api/stops';
import { TripsApiReducerPath }           from '@store/api/trips';
import { TripsApiReducer }               from '@store/api/trips';

export default (history) => withReduxStateSync(combineReducers({
    loading:                         LoadingReducer,
    stagingAuthentication:           StagingAuthenticationReducer,
    user:                            UserReducer,
    window:                          WindowReducer,
    map:                             MapReducer,
    history:                         HistoryReducer,
    search:                          SearchReducer,
    quickView:                       QuickViewReducer,
    [RegionApiReducerPath]:          RegionApiReducer,
    [PolylineApiReducerPath]:        PolylineApiReducer,
    [ContextApiReducerPath]:         ContextApiReducer,
    [StopsApiReducerPath]:           StopsApiReducer,
    [RoutesApiReducerPath]:          RoutesApiReducer,
    [TripsApiReducerPath]:           TripsApiReducer,
    [ConnectionsViewApiReducerPath]: ConnectionsViewApiReducer,
    [CalendarApiReducerPath]:        CalendarApiReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
}));
