//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import _                     from 'lodash';
import { useTranslation }    from 'react-i18next';

import CircleIcon            from '@stateless/atomic/CircleIcon';
import CircleIconTheme       from '@stateless/atomic/CircleIcon/CircleIconTheme';
import IconColor             from '@stateless/atomic/Icon/IconColor';
import IconType              from '@stateless/atomic/Icon/IconType';
import SidebarButtonListItem from '@stateless/composed/SidebarButtonListItem';
import SidebarSeparator      from '@stateless/composed/SidebarSeparator';

const propTypes = {};

const SidebarInformationList = () => {
    const { t }         = useTranslation(null, {
        keyPrefix: 'components.sidebar',
    });
    const favoriteItems = [
        {
            title:           'Friedrichshafen',
            iconType:        IconType.train,
            circleIconTheme: CircleIconTheme.yellow,
            onButtonClicked: _.noop,
        },
        {
            title:           'Friedrichshafen',
            iconType:        IconType.bus,
            circleIconTheme: CircleIconTheme.transparent,
            onButtonClicked: _.noop,
        },
        {
            title:           'Meckenbeuren',
            iconType:        IconType.train,
            circleIconTheme: CircleIconTheme.gray,
            onButtonClicked: _.noop,
        },
        {
            title:           'Friedrichshafen Messe',
            iconType:        IconType.train,
            circleIconTheme: CircleIconTheme.darkAbyss,
            onButtonClicked: _.noop,
        },
        {
            title:           'Weingarten Berg',
            iconType:        IconType.standOut,
            circleIconTheme: CircleIconTheme.red,
            onButtonClicked: _.noop,
        },
        {
            title:           'Friedrichshafen Messe',
            iconType:        IconType.train,
            circleIconTheme: CircleIconTheme.darkAbyss,
            onButtonClicked: _.noop,
        },
        {
            title:           'Weingarten Berg',
            iconType:        IconType.standOut,
            circleIconTheme: CircleIconTheme.red,
            onButtonClicked: _.noop,
        },
    ];

    function renderIconView(iconType, circleIconTheme) {
        if (!circleIconTheme) {
            return null;
        }

        return (
            <CircleIcon
                iconType={iconType}
                circleIconTheme={circleIconTheme}
            />
        );
    }

    function renderFavoriteItems() {
        return favoriteItems.map((item) => {
            const { title, iconType, onButtonClicked, titleView, circleIconTheme } = item;

            return (
                <SidebarButtonListItem
                    key={title}
                    iconView={renderIconView(iconType, circleIconTheme)}
                    title={title}
                    iconType={iconType}
                    buttonIconType={IconType.trash}
                    onButtonClicked={onButtonClicked}
                    titleView={titleView}
                />
            );
        });
    }

    return (
        <div>
            <SidebarSeparator
                text={t('favorites')}
                iconType={IconType.star}
                iconColor={IconColor.yellow}
            />
            {renderFavoriteItems()}
        </div>
    );
};

SidebarInformationList.propTypes = propTypes;

export default SidebarInformationList;
