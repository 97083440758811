//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useTranslation } from 'react-i18next';

import PropTypes              from '@components/PropTypes';
import Spacer                 from '@stateless/atomic/Spacer';
import SidebarFavoriteList    from '@stateless/composed/SidebarFavoriteList';
import SidebarInformationList from '@stateless/composed/SidebarInformationList';
import SidebarToggleHeader    from '@stateless/composed/SidebarToggleHeader';

import styles from './styles.module.scss';

const propTypes = {
    expanded:               PropTypes.bool,
    onSidebarToggleClicked: PropTypes.func,
};

const SidebarMapOptionsContent = ({
    expanded,
    onSidebarToggleClicked,
}) => {
    const { t } = useTranslation(null, {
        keyPrefix: 'components.sidebar',
    });

    return (
        <div
            className={styles.sidebarMapOptionsComponent}
        >
            <SidebarToggleHeader
                expanded={expanded}
                onSidebarToggleClicked={onSidebarToggleClicked}
                headline={t('visibleElements')}
            />
            <Spacer height={50} />
            <SidebarInformationList />
            <Spacer height={50} />
            <SidebarFavoriteList />
        </div>
    );
};

SidebarMapOptionsContent.propTypes = propTypes;

export default SidebarMapOptionsContent;
