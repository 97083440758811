//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes                from '@components/PropTypes';
import SidebarDatePickerContent from '@connected/SidebarDatePickerContent';
import SidebarMapOptionsContent from '@connected/SidebarMapOptionsContent';
import SidebarTheme             from '@constants/SidebarTheme';
import Sidebar                  from '@stateless/composed/Sidebar';
import SidebarPosition          from '@stateless/composed/Sidebar/SidebarPosition';
import SidebarType              from '@stateless/composed/Sidebar/SidebarType';

import styles from './styles.module.scss';

const propTypes = {
    expanded:               PropTypes.bool,
    onSidebarToggleClicked: PropTypes.func,
    theme:                  PropTypes.oneOfObjectValues(SidebarTheme),
};

const MapActionsSidebar = ({
    expanded               = false,
    onSidebarToggleClicked = _.noop,
    theme                  = SidebarTheme.default,
}) => {
    function renderMapOptionsContent() {
        return (
            <SidebarMapOptionsContent
                key={'mapOptionsContent'}
                expanded={expanded}
                onSidebarToggleClicked={onSidebarToggleClicked}
            />
        );
    }

    function renderDatePickerContent() {
        return (
            <SidebarDatePickerContent
                key={'datePickerContent'}
                expanded={expanded}
                onSidebarToggleClicked={onSidebarToggleClicked}
            />
        );
    }

    const sidebarContentItems = [
        {
            render:           renderMapOptionsContent,
            visibleCondition: expanded,
        },
        {
            render:           renderDatePickerContent,
            visibleCondition: !expanded,
        },
    ];

    function renderSidebarContent() {
        return _.map(sidebarContentItems, (sidebarContentItem) => {
            return (
                <div
                    className={classNames(
                        styles.mapActionsSidebarContent,
                        {
                            [styles.visible]:   sidebarContentItem.visibleCondition,
                            [styles.lightGray]: theme === SidebarTheme.lightGray,
                        },
                    )}
                >
                    {sidebarContentItem.render()}
                </div>
            );
        });
    }

    return (
        <Sidebar
            type={SidebarType.previewCollapsed}
            onSidebarToggleClicked={onSidebarToggleClicked}
            expanded={expanded}
            position={SidebarPosition.right}
        >
            {renderSidebarContent()}
        </Sidebar>
    );
};

MapActionsSidebar.propTypes = propTypes;

export default MapActionsSidebar;
