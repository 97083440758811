//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes                      from '@components/PropTypes';
import Icon                           from '@stateless/atomic/Icon';
import IconColor                      from '@stateless/atomic/Icon/IconColor';
import SidebarListItemBackgroundColor from '@stateless/composed/SidebarListItem/SidebarListItemBackgroundColor';

import styles from './styles.module.scss';

const propTypes = {
    backgroundColor: PropTypes.oneOfObjectKeys(SidebarListItemBackgroundColor),
    customView:      PropTypes.children,
    iconColor:       PropTypes.iconColor,
    iconType:        PropTypes.iconType,
    iconView:        PropTypes.children,
    onItemClicked:   PropTypes.func,
    smallPadding:    PropTypes.bool,
    title:           PropTypes.string,
    titleView:       PropTypes.children,
};

const SidebarListItem = ({
    title           = '',
    titleView       = null,
    iconType        = null,
    iconColor       = IconColor.black,
    iconView        = null,
    customView      = null,
    backgroundColor = SidebarListItemBackgroundColor.default,
    onItemClicked   = null,
    smallPadding    = false,
}) => {
    const renderTitle = () => {
        if (titleView) {
            return titleView;
        }

        return title;
    };
    const renderIcon  = () => {
        if (iconView) {
            return iconView;
        }

        return (
            <Icon
                iconType={iconType}
                iconColor={iconColor}
            />
        );
    };

    return (
        <button
            className={classNames(
                styles.sidebarListItemComponent,
                {
                    [styles.lightGrayBackground]: backgroundColor === SidebarListItemBackgroundColor.lightGray,
                    [styles.cursorPointer]:       onItemClicked,
                    [styles.smallPadding]:        smallPadding,
                },
            )}
            onClick={onItemClicked}
        >
            <div
                className={
                    styles.sidebarListItemIconWrapper
                }
            >
                {renderIcon()}
            </div>
            <div className={styles.sidebarListItemTitle}>
                {renderTitle()}
            </div>
            <div className={styles.sidebarListItemCustomViewWrapper}>
                {customView}
            </div>
        </button>
    );
};

SidebarListItem.propTypes = propTypes;

export default SidebarListItem;
