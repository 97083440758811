//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { useCallback } from 'react';
import { useMemo }     from 'react';

import _                from 'lodash';
import { MapContainer } from 'react-leaflet';
import { TileLayer }    from 'react-leaflet';
import { useSelector }  from 'react-redux';
import { useDispatch }  from 'react-redux';

import { useMapContext } from '@components/MapProvider';
import MapEvents         from '@connected/MapEvents';
import MapRouteLayer     from '@connected/MapRouteLayer';
import ContextType       from '@constants/ContextType';
import useMapNavigation  from '@hooks/useMapNavigation';
import useRegionApi      from '@hooks/useRegionApi';
import { useMapActions } from '@slices/map';
import MapControlLayer   from '@stateless/composed/MapControlLayer';
import MapLoadingLayer   from '@stateless/composed/MapLoadingLayer';
import StopMapPin        from '@stateless/composed/StopMapPin';
import StopMarkerCluster from '@stateless/composed/StopMarkerCluster';

import styles from './styles.module.scss';

const TILE_THEME_URL = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';

const propTypes = {};

const MapLayer = () => {
    // eslint-disable-next-line no-unused-vars
    useMapNavigation();

    const dispatch                      = useDispatch();
    const { setSelectedContext }        = useMapActions(dispatch);
    const { stops, routes, isFetching } = useRegionApi();
    const zoom                          = useSelector((state) => state.map.zoom);
    const coordinates                   = useSelector((state) => state.map.coordinates);
    const { setMapInstance }            = useMapContext();
    const renderRoutes                  = useCallback(() => {
        return _.map(routes, (route) => {
            return (
                <MapRouteLayer
                    key={route.id}
                    route={route}
                />
            );
        });
    }, [routes]);
    const createOnMarkerClicked         = (stop) => () => {
        setSelectedContext({
            id:   stop.id,
            type: ContextType.stop,
        });
    };
    const stopMarkers                   = useMemo(() => {
        return _.map(stops, (stop) => {
            const markerComponent = (
                <StopMapPin
                    key={stop.id}
                    type={stop.type}
                    passengerVolumeInPercent={stop.usage}
                    inactive={!stop.active}
                />
            );

            return {
                position:  {
                    latitude:  stop.coordinates.latitude,
                    longitude: stop.coordinates.longitude,
                },
                component: markerComponent,
                onClick:   createOnMarkerClicked(stop),
            };
        });
    }, [stops]);

    function onMapReady(data) {
        const mapInstance = data.target;
        setMapInstance(mapInstance);
    }

    return (
        <div className={styles.mapLayerComponent}>
            <MapContainer
                whenReady={onMapReady}
                center={[coordinates.latitude, coordinates.longitude]}
                zoom={zoom}
                zoomControl={false}
            >
                <TileLayer url={TILE_THEME_URL} />
                <StopMarkerCluster markers={stopMarkers} />
                {renderRoutes()}
                <MapControlLayer />
                <MapLoadingLayer isLoading={isFetching} />
                <MapEvents />
            </MapContainer>
        </div>
    );
};

MapLayer.propTypes = propTypes;

export default MapLayer;
