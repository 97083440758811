//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useEffect } from 'react';
import { useRef }    from 'react';

import { useMapContext } from '@components/MapProvider';
import ContextType       from '@constants/ContextType';
import useContextApi     from '@hooks/useContextApi';

const useMapNavigation = () => {
    const { mapInstance }             = useMapContext();
    const { data, error, isFetching } = useContextApi();
    const prevIsFetching              = useRef(isFetching);
    const navigateTo                  = ({
        latitude,
        longitude,
        zoom,
    }) => {
        if (mapInstance) {
            mapInstance.flyTo([latitude, longitude], zoom);
        }
    };
    const navigateToBounds            = ({ top, right, bottom, left }) => {
        const invalidBounds = (
            !top ||
            !right ||
            !bottom ||
            !left
        );

        if (
            mapInstance &&
            !invalidBounds
        ) {
            const bounds = [
                [right, bottom],
                [left, top],
            ];

            mapInstance.flyToBounds(bounds);
        }
    };
    const navigateToSelectedContext   = () => {
        if (
            error ||
            !data
        ) {
            return;
        }

        const requestContextType = data.request.type;

        if (requestContextType === ContextType.stop) {
            const coordinates = data.context.overview.coordinates;

            navigateTo(coordinates);
        } else if (requestContextType === ContextType.route) {
            const bounds = data.context.overview.route.boundingBox;

            navigateToBounds(bounds);
        }
    };

    useEffect(() => {
        if (
            prevIsFetching.current &&
            !isFetching
        ) {
            navigateToSelectedContext();

            return;
        }

        prevIsFetching.current = isFetching;
    }, [data, isFetching]);

    return {
        navigateTo,
        navigateToSelectedContext,
    };
};

export default useMapNavigation;
