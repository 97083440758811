//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createApi }      from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import ApiUrls from '@constants/ApiUrls';
import Url     from '@helper/Url';

const CACHING_DURATION_IN_DAYS = 180;

const CACHING_DURATION_IN_SECONDS = CACHING_DURATION_IN_DAYS * 24 * 60 * 60;
export const polylineApi          = createApi({
    reducerPath: 'polylineApi',
    baseQuery:   fetchBaseQuery({
        baseUrl: Url.createEndpoint(ApiUrls.API_POLYLINE_URL),
    }),
    endpoints:   (builder) => (
        {
            fetchPolyline: builder.query({
                query:             ({ routeId }) => (
                    {
                        url:    '',
                        params: {
                            routeId,
                        },
                    }
                ),
                keepUnusedDataFor: CACHING_DURATION_IN_SECONDS,
            }),
        }
    ),
});

export const { useFetchPolylineQuery } = polylineApi;

export const PolylineApiReducer = polylineApi.reducer;

export const PolylineApiReducerPath = polylineApi.reducerPath;

export const PolylineApiMiddleware = polylineApi.middleware;

export default polylineApi;
