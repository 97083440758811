//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useTranslation } from 'react-i18next';

import PropTypes               from '@components/PropTypes';
import SelectedDateRangeLabel  from '@connected/SelectedDateRangeLabel';
import withContextPanelPadding from '@hoc/ContextPanelPadding';
import IconType                from '@stateless/atomic/Icon/IconType';
import UppercaseLabel          from '@stateless/atomic/UppercaseLabel';
import ContextConnectionItem   from '@stateless/composed/ContextConnectionItem';
import ContextTitle            from '@stateless/composed/ContextTitle';

import styles from './styles.module.scss';

const propTypes = {
    context: PropTypes.object,
};

const ContextConnections = ({
    context = null,
}) => {
    const { t }                        = useTranslation(null, {
        keyPrefix: 'components.context',
    });
    const renderContextConnectionItems = () => {
        return context?.transits?.map((connectionItem, index) => {
            const key = `context_connection_${index}`;

            return (
                <ContextConnectionItem
                    key={key}
                    connection={connectionItem}
                    usage={context.usage}
                />
            );
        });
    };

    return (
        <div className={styles.contextConnectionsComponent}>
            {withContextPanelPadding(<div className={styles.headlineRow}>
                <ContextTitle
                    text={t('connectionsCombinations')}
                />
                <SelectedDateRangeLabel />
            </div>)}
            {withContextPanelPadding(<UppercaseLabel
                text={t('averageAllConnections')}
                iconType={IconType.average}
            />)}
            <div className={styles.connectionsWrapper}>
                {renderContextConnectionItems()}
            </div>
        </div>
    );
};

ContextConnections.propTypes = propTypes;

export default ContextConnections;
