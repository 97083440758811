//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes       from '@components/PropTypes';
import ClassToggle     from '@helper/ClassToggler';
import CircleIcon      from '@stateless/atomic/CircleIcon';
import CircleIconTheme from '@stateless/atomic/CircleIcon/CircleIconTheme';
import ShortNameLabel  from '@stateless/atomic/ShortNameLabel';

import styles from './styles.module.scss';

const propTypes = {
    iconType:   PropTypes.iconType,
    labelItems: PropTypes.arrayOf(PropTypes.shape({
        text:      PropTypes.string,
        color:     PropTypes.string,
        onClicked: PropTypes.func,
        textColor: PropTypes.string,
    })),
};

const ShortNameLabelGroup = ({
    iconType   = null,
    labelItems = [],
}) => {
    const addScrollIndicatorClass = (event) => {
        const element         = event.target;
        const scrolledToRight = element.scrollLeft === element.scrollWidth - element.clientWidth;
        const scrolledToLeft  = element.scrollLeft === 0;

        ClassToggle.toggleClass(scrolledToRight, element, styles.scrollEnd);
        ClassToggle.toggleClass(scrolledToLeft, element, styles.scrollStart);
    };

    function renderLabels() {
        return _.map(labelItems, (labelItem) => {
            const { text, color, textColor, onClicked } = labelItem;

            return (
                <ShortNameLabel
                    textWrap={false}
                    onLabelClicked={onClicked}
                    key={text}
                    text={text}
                    color={color}
                    textColor={textColor}
                />
            );
        });
    }

    return (
        <div className={styles.shortNameLabelGroupComponentWrapper}>

            <div
                className={classNames(
                    styles.shortNameLabelGroupComponent,
                    styles.scrollStart,
                )}
                onScroll={addScrollIndicatorClass}
            >
                <CircleIcon
                    iconType={iconType}
                    circleIconTheme={CircleIconTheme.darkAbyss}
                />
                {renderLabels()}
            </div>
        </div>
    );
};

ShortNameLabelGroup.propTypes = propTypes;

export default ShortNameLabelGroup;
