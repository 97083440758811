//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    bus:             'bus',
    burger:          'burger',
    cross:           'cross',
    arrowRight:      'arrowRight',
    average:         'average',
    cloud:           'cloud',
    construction:    'construction',
    connections:     'connections',
    logo:            'logo',
    passengerVolume: 'passengerVolume',
    punctuality:     'punctuality',
    star:            'star',
    standOut:        'standOut',
    train:           'train',
    change:          'change',
    warning:         'warning',
    line:            'line',
    calendar:        'calendar',
    chevronDown:     'chevronDown',
    sort:            'sort',
    chevronRight:    'chevronRight',
    chevronLeft:     'chevronLeft',
    chevronUp:       'chevronUp',
    dashboard:       'dashboard',
    fullscreen:      'fullscreen',
    plus:            'plus',
    minus:           'minus',
    trash:           'trash',
    user:            'user',
    clock:           'clock',
    lock:            'lock',
    search:          'search',
    mail:            'mail',
    profile:         'profile',
    document:        'document',
    logout:          'logout',
});
