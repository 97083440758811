//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes            from '@components/PropTypes';
import StopType             from '@constants/StopType';
import LabeledTextInput     from '@stateless/atomic/LabeledTextInput';
import LoadingSpinner       from '@stateless/atomic/LoadingSpinner';
import SearchResultListItem from '@stateless/composed/SearchResultListItem';

import styles from './styles.module.scss';

const propTypes = {
    isLoading:       PropTypes.bool,
    labelText:       PropTypes.string,
    onInputChanges:  PropTypes.func,
    onResultClicked: PropTypes.func,
    onSubmit:        PropTypes.func,
    searchResults:   PropTypes.array,
    showResults:     PropTypes.bool,
    value:           PropTypes.string,
};

const StopSearchInput = ({
    value           = '',
    onInputChanges  = _.noop,
    onResultClicked = _.noop,
    labelText       = '',
    searchResults   = [],
    showResults     = false,
    isLoading       = false,
    onSubmit        = _.noop,
}) => {
    function renderSearchResults() {
        if (!showResults) {
            return null;
        }

        if (isLoading) {
            return (
                <div className={styles.loadingWrapper}>
                    <LoadingSpinner isLoading={isLoading} />
                </div>
            );
        }

        return _.map(searchResults, (result) => {
            const resultObject = {
                stopType: StopType.busStop, // TODO: https://lulububu.atlassian.net/browse/BODOBIG-772
                name:     result.nameWithLocation,
                region:   '', // TODO: https://lulububu.atlassian.net/browse/BODOBIG-771
                routes:   result.routes,
            };

            return (
                <SearchResultListItem
                    key={result.id}
                    result={resultObject}
                    onItemClicked={onResultClicked(result)}
                />
            );
        });
    }

    function handleSearchSubmit(event) {
        event.preventDefault();
        onSubmit();
    }

    return (
        <div className={styles.stopSearchInputComponent}>
            <form onSubmit={handleSearchSubmit}>
                <LabeledTextInput
                    value={value}
                    onChange={onInputChanges}
                    labelText={labelText}
                />
            </form>
            <div className={styles.searchResultsWrapper}>
                {renderSearchResults()}
            </div>
        </div>
    );
};

StopSearchInput.propTypes = propTypes;

export default StopSearchInput;
