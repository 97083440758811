//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import { useDispatch } from 'react-redux';

import Environment           from '@helper/Environment';
import { useHistoryActions } from '@slices/history';
import IconType              from '@stateless/atomic/Icon/IconType';
import IconButton            from '@stateless/atomic/IconButton';
import IconButtonStyle       from '@stateless/atomic/IconButton/IconButtonStyle';
import calendarApi           from '@store/api/calendar';
import contextApi            from '@store/api/context';
import polylineApi           from '@store/api/polyline';
import regionApi             from '@store/api/region';
import routesApi             from '@store/api/routes';
import stopsApi              from '@store/api/stops';
import tripsApi              from '@store/api/trips';

const CacheClearButton = () => {
    const dispatch          = useDispatch();
    const HistoryActions    = useHistoryActions(dispatch);
    const [icon, setIcon]   = useState(IconType.trash);
    const [style, setStyle] = useState(IconButtonStyle.red);

    function startSuccessProcedure() {
        setIcon(IconType.check);
        setStyle(IconButtonStyle.green);
        setTimeout(() => {
            setIcon(IconType.trash);
            setStyle(IconButtonStyle.red);
        }, 3000);
    }

    function onClearCacheClicked() {
        dispatch(regionApi.util.resetApiState());
        dispatch(polylineApi.util.resetApiState());
        dispatch(contextApi.util.resetApiState());
        dispatch(stopsApi.util.resetApiState());
        dispatch(calendarApi.util.resetApiState());
        dispatch(tripsApi.util.resetApiState());
        dispatch(routesApi.util.resetApiState());
        HistoryActions.clearHistory();
        startSuccessProcedure();
    }

    if (!Environment.isDevelopment()) {
        return null;
    }

    return (
        <IconButton
            iconButtonStyle={style}
            onIconButtonClicked={onClearCacheClicked}
            iconType={icon}
            iconColor={IconButtonStyle.white}
        />
    );
};

export default CacheClearButton;
