//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React        from 'react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import IconType                    from '@stateless/atomic/Icon/IconType';
import SidebarSeparator            from '@stateless/composed/SidebarSeparator';
import SidebarSwitchToggleListItem from '@stateless/composed/SidebarSwitchToggleListItem';

const propTypes = {};

const SidebarInformationList = () => {
    const [showLines, setShowLines]                                   = useState(false);
    const [showBusStops, setShowBusStops]                             = useState(false);
    const [showTrainStations, setShowTrainStations]                   = useState(false);
    const [showPassengerVolume, setShowPassengerVolume]               = useState(false);
    const [showDisturbances, setShowDisturbances]                     = useState(false);
    const [showConstructionSites, setShowConstructionSites]           = useState(false);
    const [showDelays, setShowDelays]                                 = useState(false);
    const [showConnectionNotReachable, setShowConnectionNotReachable] = useState(false);
    const [showConnectionCombinations, setShowConnectionCombinations] = useState(false);
    const [showWeather, setShowWeather]                               = useState(false);
    const { t }                                                       = useTranslation(null, {
        keyPrefix: 'components.sidebar',
    });
    const toggleValue                                                 = (value, setValue) => () => {
        setValue(!value);
    };
    const visibilityItems                                             = [
        {
            title:           t('lines'),
            iconType:        IconType.line,
            checked:         showLines,
            onToggleClicked: toggleValue(showLines, setShowLines),
        },
        {
            title:           t('busStops'),
            iconType:        IconType.bus,
            checked:         showBusStops,
            onToggleClicked: toggleValue(showBusStops, setShowBusStops),
        },
        {
            title:           t('trainStations'),
            iconType:        IconType.train,
            checked:         showTrainStations,
            onToggleClicked: toggleValue(showTrainStations, setShowTrainStations),
        },
        {
            title:           t('passengerVolume'),
            iconType:        IconType.passengerVolume,
            checked:         showPassengerVolume,
            onToggleClicked: toggleValue(showPassengerVolume, setShowPassengerVolume),
        },
        {
            title:           t('disturbances'),
            iconType:        IconType.warning,
            checked:         showDisturbances,
            onToggleClicked: toggleValue(showDisturbances, setShowDisturbances),
        },
        {
            title:           t('constructionSites'),
            iconType:        IconType.construction,
            checked:         showConstructionSites,
            onToggleClicked: toggleValue(showConstructionSites, setShowConstructionSites),
        },
        {
            title:           t('delays'),
            iconType:        IconType.punctuality,
            checked:         showDelays,
            onToggleClicked: toggleValue(showDelays, setShowDelays),
        },
        {
            title:           t('connectionNotReachable'),
            iconType:        IconType.standOut,
            checked:         showConnectionNotReachable,
            onToggleClicked: toggleValue(showConnectionNotReachable, setShowConnectionNotReachable),
        },
        {
            title:           t('connectionCombinations'),
            iconType:        IconType.connections,
            checked:         showConnectionCombinations,
            onToggleClicked: toggleValue(showConnectionCombinations, setShowConnectionCombinations),
        },
        {
            title:           t('weather'),
            iconType:        IconType.cloud,
            checked:         showWeather,
            onToggleClicked: toggleValue(showWeather, setShowWeather),
        },
    ];

    function renderVisibilityItems() {
        return visibilityItems.map((item) => {
            const { title, iconType, checked, onToggleClicked } = item;

            return (
                <SidebarSwitchToggleListItem
                    key={title}
                    iconType={iconType}
                    title={title}
                    checked={checked}
                    onToggleClicked={onToggleClicked}
                />
            );
        });
    }

    return (
        <div>
            <SidebarSeparator text={t('information')} />
            {renderVisibilityItems()}
        </div>
    );
};

SidebarInformationList.propTypes = propTypes;

export default SidebarInformationList;
