//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import hash            from 'object-hash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import DateRangeHelper                from '@helper/DateRange';
import { useHistoryActions }          from '@slices/history';
import { useFetchContextQuery }       from '@store/api/context';
import { selectContextHistoryByHash } from '@store/selectors/history';

const useContextApi = () => {
    const selectedContext                        = useSelector((state) => state.map.selectedContext);
    const selectedDateRange                      = useSelector((state) => state.map.selectedDateRange);
    const dispatch                               = useDispatch();
    const historyActions                         = useHistoryActions(dispatch);
    const dateRangeString                        = DateRangeHelper.getRangeString(selectedDateRange);
    const isContextSelected                      = !!selectedContext?.id;
    const options                                = {
        id:        selectedContext?.id,
        type:      selectedContext?.type,
        dateRange: dateRangeString,
    };
    const { data, error, isLoading, isFetching } = useFetchContextQuery(options, {
        skip: !isContextSelected,
    });
    const optionsHash                            = hash(options);
    const existingHistoryRecord                  = useSelector((state) => selectContextHistoryByHash(state, optionsHash));

    if (
        !existingHistoryRecord &&
        isContextSelected
    ) {
        historyActions.addContextHistory({
            context: {
                ...options,
                hash: optionsHash,
            },
        });
    }

    return {
        data,
        error,
        isLoading,
        isFetching,
    };
};

export default useContextApi;
