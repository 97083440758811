//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';
import { useMemo }  from 'react';

import classNames      from 'classnames';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import useContextApi             from '@hooks/useContextApi';
import { useMapActions }         from '@slices/map';
import IconType                  from '@stateless/atomic/Icon/IconType';
import IconButton                from '@stateless/atomic/IconButton';
import ContextInformationContent from '@stateless/composed/ContextInformationContent';
import ContextInformationHeader  from '@stateless/composed/ContextInformationHeader';
import ContextInformationTabBar  from '@stateless/composed/ContextInformationTabBar';

import styles from './styles.module.scss';

const ContextInformationPanel = () => {
    const dispatch                    = useDispatch();
    const selectedContext             = useSelector((state) => state.map.selectedContext);
    const showQuickViewSidebarDetails = useSelector((state) => state.quickView.showQuickViewSidebarDetails);
    const mapAction                   = useMapActions(dispatch);
    const [activeTab, setActiveTab]   = useState(null);
    const { data, isFetching }        = useContextApi();
    const showContextInformationPanel = useMemo(() => selectedContext?.id && !showQuickViewSidebarDetails, [selectedContext, showQuickViewSidebarDetails]);

    function createOnTabButtonClicked(tab) {
        return () => {
            let newTab = tab;

            if (activeTab === tab) {
                newTab = null;
            }

            setActiveTab(newTab);
        };
    }

    function unselectContextClicked() {
        mapAction.setSelectedContext({
            id:   null,
            type: null,
        });
        setActiveTab(null);
    }

    function closeActiveTabClicked() {
        setActiveTab(null);
    }

    function renderCloseActiveTabRow() {
        if (activeTab) {
            return (
                <div
                    className={styles.closeActiveTabRow}
                >
                    <IconButton
                        onIconButtonClicked={closeActiveTabClicked}
                        iconType={IconType.chevronDown}
                    />
                </div>
            );
        }

        return null;
    }

    return (
        <div
            className={classNames(
                styles.contextInformationPanelComponent,
                {
                    [styles.open]: showContextInformationPanel,
                },
            )}
        >
            {renderCloseActiveTabRow()}
            <ContextInformationHeader
                context={data?.context}
                type={selectedContext?.type}
                isLoading={isFetching}
                onCloseClicked={unselectContextClicked}
            />
            <ContextInformationContent
                activeTab={activeTab}
                isLoading={isFetching}
                context={data?.context}
            />
            <ContextInformationTabBar
                activeTab={activeTab}
                createOnTabClicked={createOnTabButtonClicked}
            />
        </div>
    );
};

export default ContextInformationPanel;
