//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React        from 'react';
import { useMemo }  from 'react';
import { useState } from 'react';

import { de as germanLocale } from 'date-fns/locale';
import _                      from 'lodash';
import moment                 from 'moment';
import { DateRange }          from 'react-date-range';
import { useTranslation }     from 'react-i18next';

import PropTypes                 from '@components/PropTypes';
import { DateRangePart }         from '@helper/DateRange';
import IconColor                 from '@stateless/atomic/Icon/IconColor';
import IconType                  from '@stateless/atomic/Icon/IconType';
import IconButton                from '@stateless/atomic/IconButton';
import LabeledTextInput          from '@stateless/atomic/LabeledTextInput';
import SimpleButton              from '@stateless/atomic/SimpleButton';
import SimpleButtonTheme         from '@stateless/atomic/SimpleButton/SimpleButtonTheme';
import TextInputType             from '@stateless/atomic/TextInput/TextInputType';
import { useFetchCalendarQuery } from '@store/api/calendar';
import Colors                    from '@styles/colors.scss';

import styles from './styles.module.scss';

const propTypes = {
    onCloseClicked:  PropTypes.func,
    onDeleteClicked: PropTypes.func,
    onSaveClicked:   PropTypes.func,
    value:           PropTypes.object,
};

const CALENDAR_FETCH_DATE_RANGE = '20000101-21000101';

const proportionClasses = [
    {
        threshold:     75,
        color:         Colors.darkSky,
        legendTextKey: 'calendarLegendHigh',
    },
    {
        threshold:     50,
        color:         Colors.mediumSky,
        legendTextKey: 'calendarLegendMedium',
    },
    {
        threshold:     0,
        color:         Colors.lightSky,
        legendTextKey: 'calendarLegendLow',
    },
];

const DateRangePicker = ({
    value           = null,
    onCloseClicked  = _.noop,
    onDeleteClicked = _.noop,
    onSaveClicked   = _.noop,
}) => {
    const [localSelection, setLocalSelection] = useState(value);
    const { t }                               = useTranslation(null, {
        keyPrefix: 'components.datePicker',
    });
    const formattedDateRange                  = useMemo(() => [
        {
            startDate: new Date(localSelection[DateRangePart.from]) || new Date(),
            endDate:   new Date(localSelection[DateRangePart.to]) || new Date(),
            key:       'selection',
        },
    ], [localSelection]);
    const formattedFromValue                  = useMemo(() => {
        return moment(localSelection[DateRangePart.from]).format('YYYY-MM-DD');
    }, [localSelection]);
    const formattedToValue                    = useMemo(() => {
        return moment(localSelection[DateRangePart.to]).format('YYYY-MM-DD');
    }, [localSelection]);
    const { data: calendarData }              = useFetchCalendarQuery({
        dateRange: CALENDAR_FETCH_DATE_RANGE,
    });

    function handleManualDateChange(dateRangePart) {
        return (event) => {
            const newDateValue = moment(event.target.value, 'YYYY-MM-DD').toDate();

            setLocalSelection((currentValue) => (
                {
                    ...currentValue,
                    [dateRangePart]: newDateValue,
                }
            ));
        };
    }

    function handleDatePickerDateChange(selection) {
        setLocalSelection({
            [DateRangePart.from]: selection.selection.startDate,
            [DateRangePart.to]:   selection.selection.endDate,
        });
    }

    function handleSaveClicked() {
        onSaveClicked(localSelection);
    }

    function renderDayContent(dayDateTime) {
        const dayMoment     = moment(dayDateTime);
        const dayString     = dayMoment.format('YYYY-M-D');
        let backgroundStyle = {};

        if (calendarData) {
            const ridesPerDayItem = _.find(calendarData.ridesPerDay, (day) => {
                return day.date === dayString;
            });
            const proportion      = ridesPerDayItem
                ? ridesPerDayItem.proportion
                : 0;
            const proportionClass = _.find(proportionClasses, (proportionClassItem) => {
                return proportion >= proportionClassItem.threshold;
            });
            backgroundStyle       = {
                backgroundColor: proportionClass.color,
            };
        }

        return (
            <span
                className={styles.dayData}
                style={backgroundStyle}
            >
                {dayMoment.format('D')}
            </span>
        );
    }

    function renderLegendItems() {
        return proportionClasses.map((proportionClass) => {
            const itemColorStyle = {
                backgroundColor: proportionClass.color,
            };

            return (
                <div
                    key={proportionClass.threshold}
                    className={styles.legendItem}
                >
                    <div
                        className={styles.legendItemColor}
                        style={itemColorStyle}
                    />
                    <span>
                        {t(proportionClass.legendTextKey)}
                    </span>
                </div>
            );
        });
    }

    return (
        <div className={styles.dateRangePickerComponent}>
            <div className={styles.datePickerHeaderRow}>
                <div className={styles.inputWrapper}>
                    <LabeledTextInput
                        labelText={t('from')}
                        type={TextInputType.date}
                        onChange={handleManualDateChange(DateRangePart.from)}
                        value={formattedFromValue}
                    />
                    <LabeledTextInput
                        labelText={t('to')}
                        type={TextInputType.date}
                        onChange={handleManualDateChange(DateRangePart.to)}
                        value={formattedToValue}
                    />
                </div>
                <div className={styles.buttonWrapper}>
                    <SimpleButton
                        text={t('save')}
                        onClick={handleSaveClicked}
                    />
                    <IconButton
                        iconType={IconType.cross}
                        onIconButtonClicked={onCloseClicked}
                        iconColor={IconColor.black}
                    />
                </div>
            </div>
            <DateRange
                onChange={handleDatePickerDateChange}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={formattedDateRange}
                direction="horizontal"
                showDateDisplay={false}
                locale={germanLocale}
                className={styles.dateRangePicker}
                dayContentRenderer={renderDayContent}
            />
            <div className={styles.datePickerFooterRow}>
                <div className={styles.dataAmountLegend}>
                    {renderLegendItems()}
                </div>
                <SimpleButton
                    text={t('delete')}
                    theme={SimpleButtonTheme.light}
                    onClick={onDeleteClicked}
                />
            </div>
        </div>
    );
};

DateRangePicker.propTypes = propTypes;

export default DateRangePicker;
