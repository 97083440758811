//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes from '@components/PropTypes';
import Colors    from '@styles/colors.scss';

import styles from './styles.module.scss';

const propTypes = {
    color:          PropTypes.string,
    fixedWidth:     PropTypes.number,
    onLabelClicked: PropTypes.func,
    text:           PropTypes.string,
    textColor:      PropTypes.string,
    textWrap:       PropTypes.bool,
};

const ShortNameLabel = ({
    text           = '',
    color          = Colors.blue,
    textColor      = Colors.white,
    onLabelClicked = _.noop,
    fixedWidth     = 0,
    textWrap       = true,
}) => {
    const labelStyle = useMemo(() => {
        const inlineStyle = {
            backgroundColor: color,
            color:           textColor,
        };

        if (fixedWidth) {
            inlineStyle.width = fixedWidth;
        }

        return inlineStyle;
    }, [color, textColor, fixedWidth]);

    return (
        <div
            style={labelStyle}
            className={classNames(styles.shortNameLabelComponent, {
                [styles.shortNameLabelComponentTextWrap]: textWrap,
            })}
            onClick={onLabelClicked}
        >
            {text}
        </div>
    );
};

ShortNameLabel.propTypes = propTypes;

export default ShortNameLabel;
