//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { useMemo }     from 'react';
import { useCallback } from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes       from '@components/PropTypes';
import StopType        from '@constants/StopType';
import CircleIconSize  from '@stateless/atomic/CircleIcon/CircleIconSize';
import CircleIconTheme from '@stateless/atomic/CircleIcon/CircleIconTheme';
import IconType        from '@stateless/atomic/Icon/IconType';
import MapPin          from '@stateless/atomic/MapPin';

import styles from './styles.module.scss';

const propTypes = {
    inactive:                 PropTypes.bool,
    passengerVolumeInPercent: PropTypes.number,
    type:                     PropTypes.stopType,
};

const SMALL_WORKLOAD_THRESHOLD = 20;

const MEDIUM_WORKLOAD_THRESHOLD = 50;

const BIG_WORKLOAD_THRESHOLD = 80;

const workloadConditions = [
    {
        threshold: SMALL_WORKLOAD_THRESHOLD,
        className: styles.small,
    },
    {
        threshold: BIG_WORKLOAD_THRESHOLD,
        className: styles.big,
    },
    {
        threshold: MEDIUM_WORKLOAD_THRESHOLD,
        className: styles.medium,
    },
];

const StopMapPin = ({
    type                     = StopType.busStop,
    passengerVolumeInPercent = PropTypes.number,
    inactive                 = false,
}) => {
    const iconType                     = useMemo(() => {
        switch (type) {
            case StopType.busStop:
                return IconType.bus;
            case StopType.trainStation:
                return IconType.train;
            default:
                return IconType.bus;
        }
    }, [type]);
    const circleTheme                  = useMemo(() => {
        if (inactive) {
            return CircleIconTheme.gray;
        }

        return CircleIconTheme.yellow;
    }, [inactive]);
    const renderPassengerWorkloadMeter = useCallback(() => {
        return _.map(workloadConditions, ({ threshold, className }) => {
            if (passengerVolumeInPercent >= threshold) {
                return (
                    <div className={styles.passengerWorkloadMeter}>
                        <div
                            key={threshold}
                            className={classNames(
                                styles.passengerWorkloadMeter,
                                className,
                            )}
                        />
                    </div>
                );
            }

            return null;
        });
    }, [passengerVolumeInPercent]);

    return (
        <MapPin
            iconType={iconType}
            circleIconSize={CircleIconSize.big}
            circleTheme={circleTheme}
            decoration={renderPassengerWorkloadMeter()}
        />
    );
};

StopMapPin.propTypes = propTypes;

export default StopMapPin;
