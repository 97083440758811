//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { configureStore }            from '@reduxjs/toolkit';
import * as Sentry                   from '@sentry/react';
import { routerMiddleware }          from 'connected-react-router';
import { createBrowserHistory }      from 'history';
import _                             from 'lodash';
import { persistReducer }            from 'redux-persist';
import { persistStore }              from 'redux-persist';
import storage                       from 'redux-persist-indexeddb-storage';
import withReady                     from 'redux-ready';
import createSagaMiddleware          from 'redux-saga';
import sentryMiddleware              from 'redux-sentry-middleware';
import { initMessageListener }       from 'redux-state-sync';
import { createStateSyncMiddleware } from 'redux-state-sync';

import { UserActions }   from '@slices/user';
import apiMiddlewareList from '@store/middlewares';
import sagas             from '@store/sagas';

import actionBlockerMiddleware from './miscellaneous/actionBlocker';
import loadingMiddleware       from './miscellaneous/loading';
import rootReducer             from './reducer';
import apiMiddleware           from './utils/api';

const apiMiddlewareService           = apiMiddleware(); // TODO-Skeleton
const history                        = createBrowserHistory();
const sagaMiddleware                 = createSagaMiddleware({
    onError(error) {
        console.error(error);
        Sentry.captureException(error);
    },
});
const loadingMiddlewareService       = loadingMiddleware();
const sentryMiddlewareService        = sentryMiddleware(Sentry);
const actionBlockerMiddlewareService = actionBlockerMiddleware();
const persistConfig                  = {
    key:       'root',
    storage:   storage('bodo-big-data-frontend'),
    blacklist: [
        'loading',
        'router',
    ],
};
const persistedReducer               = persistReducer(
    persistConfig,
    rootReducer(history),
);
const stateSyncConfig                = {
    whitelist: [
        UserActions.logout().type,
    ],
};

export {
    history,
    sagaMiddleware,
};

export default (initialState) => {
    const allMiddlewares = [
        sentryMiddlewareService,
        actionBlockerMiddlewareService,
        loadingMiddlewareService,
        apiMiddlewareService,
        sagaMiddleware,
        ...apiMiddlewareList,
        routerMiddleware(history),
        createStateSyncMiddleware(stateSyncConfig),
    ];
    const middlewares    = _.reject(allMiddlewares, _.isNull);
    const store          = configureStore({
        reducer:        persistedReducer,
        middleware:     (getDefaultMiddleware) => (
            getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck:    false,
            }).concat(...middlewares)
        ),
        devTools:       {
            trace:      true,
            traceLimit: 25,
        },
        preloadedState: initialState,
        enhancers:      [
            withReady,
        ],
    });

    store.ready().then(() => {
        sagaMiddleware.run(sagas.root);
    });

    initMessageListener(store);
    const persistor = persistStore(store);

    return {
        store,
        persistor,
    };
};
