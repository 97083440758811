//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes       from '@components/PropTypes';
import IconColor       from '@stateless/atomic/Icon/IconColor';
import IconButton      from '@stateless/atomic/IconButton';
import SidebarListItem from '@stateless/composed/SidebarListItem';

const propTypes = {
    buttonIconColor: PropTypes.iconColor,
    buttonIconType:  PropTypes.iconType,
    iconView:        PropTypes.children,
    onButtonClicked: PropTypes.func,
    title:           PropTypes.string,
    titleView:       PropTypes.children,
};

const SidebarButtonListItem = ({
    title           = '',
    titleView       = null,
    iconView        = null,
    onButtonClicked = _.noop,
    buttonIconType  = null,
    buttonIconColor = IconColor.darkAbyss,
}) => {
    const renderButton = () => {
        return (
            <IconButton
                onIconButtonClicked={onButtonClicked}
                iconType={buttonIconType}
                iconColor={buttonIconColor}
            />
        );
    };

    return (
        <SidebarListItem
            title={title}
            customView={renderButton()}
            titleView={titleView}
            iconView={iconView}
        />
    );
};

SidebarButtonListItem.propTypes = propTypes;

export default SidebarButtonListItem;
