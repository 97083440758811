//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes       from '@components/PropTypes';
import IconColor       from '@stateless/atomic/Icon/IconColor';
import SwitchToggle    from '@stateless/atomic/SwitchToggle';
import SidebarListItem from '@stateless/composed/SidebarListItem';

const propTypes = {
    checked:         PropTypes.bool,
    iconColor:       PropTypes.iconColor,
    iconType:        PropTypes.iconType,
    onToggleClicked: PropTypes.func,
    title:           PropTypes.string,
    titleView:       PropTypes.children,
};

const SidebarSwitchToggleListItem = ({
    title           = '',
    titleView       = null,
    iconType        = null,
    iconColor       = IconColor.black,
    checked         = false,
    onToggleClicked = _.noop,
}) => {
    const renderSwitch = () => {
        return (
            <SwitchToggle
                onToggleClicked={onToggleClicked}
                checked={checked}
            />
        );
    };

    return (
        <SidebarListItem
            title={title}
            customView={renderSwitch()}
            titleView={titleView}
            iconColor={iconColor}
            iconType={iconType}
        />
    );
};

SidebarSwitchToggleListItem.propTypes = propTypes;

export default SidebarSwitchToggleListItem;
