//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames          from 'classnames';

import DefaultTypes      from '@components/DefaultTypes';
import PropTypes         from '@components/PropTypes';
import SimpleButtonTheme from '@stateless/atomic/SimpleButton/SimpleButtonTheme';

import styles from './styles.module.scss';

const propTypes = {
    disabled: PropTypes.bool,
    icon:     PropTypes.any,
    onClick:  PropTypes.func,
    text:     PropTypes.string,
    theme:    PropTypes.oneOfObjectValues(SimpleButtonTheme),
};

const SimpleButton = ({
    icon     = null,
    onClick  = DefaultTypes.func,
    text     = '',
    theme    = SimpleButtonTheme.default,
    disabled = false,
}) => {
    function renderIcon() {
        if (icon) {
            return (
                <FontAwesomeIcon icon={icon} />
            );
        }

        return null;
    }

    return (
        <button
            disabled={disabled}
            className={classNames(
                styles.simpleButton,
                {
                    [styles.light]: theme === SimpleButtonTheme.light,
                },
            )}
            onClick={onClick}
        >
            {renderIcon()}
            {text}
        </button>
    );
};

SimpleButton.propTypes = propTypes;

export default SimpleButton;
