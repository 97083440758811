//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useState } from 'react';

import { useDispatch } from 'react-redux';

import stopsApi                    from '@store/api/stops';
import { useLazySearchStopsQuery } from '@store/api/stops';

const useStopSearchFormControl = () => {
    const dispatch                                                          = useDispatch();
    const [stopSearchQuery, setStopSearchQuery]                             = useState('');
    const [displayStopResults, setDisplayStopResults]                       = useState(false);
    const [stopTrigger, { data: stopResults, isFetching: isFetchingStops }] = useLazySearchStopsQuery();

    function hideStopResults() {
        setDisplayStopResults(false);
        dispatch(stopsApi.util.resetApiState());
    }

    function showStopResults() {
        setDisplayStopResults(true);
    }

    function onStopSearchQueryChanged(event) {
        setStopSearchQuery(event.target.value);
        hideStopResults();
    }

    function executeSearchStop() {
        const options = {
            query: stopSearchQuery,
        };

        stopTrigger(options);
        showStopResults();
    }

    return {
        stopSearchQuery,
        onStopSearchQueryChanged,
        displayStopResults,
        showStopResults,
        hideStopResults,
        executeSearchStop,
        isFetchingStops,
        stopResults,
    };
};

export default useStopSearchFormControl;
