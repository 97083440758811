//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch }    from 'react-redux';

import PropTypes               from '@components/PropTypes';
import ContextType             from '@constants/ContextType';
import RouteTypeEnum           from '@constants/RouteTypeEnum';
import withContextPanelPadding from '@hoc/ContextPanelPadding';
import { useMapActions }       from '@slices/map';
import IconType                from '@stateless/atomic/Icon/IconType';
import ShortNameLabelGroup     from '@stateless/atomic/ShortNameLabelGroup';
import UppercaseLabel          from '@stateless/atomic/UppercaseLabel';

import styles from './styles.module.scss';

const propTypes = {
    context: PropTypes.object,
};

const routeTypeIconMapping = {
    [RouteTypeEnum.bus]:     IconType.bus,
    [RouteTypeEnum.train]:   IconType.train,
    [RouteTypeEnum.ferry]:   null,
    [RouteTypeEnum.unknown]: null,
};

const ContextOverview = ({
    context = null,
}) => {
    const { t }                  = useTranslation(null, {
        keyPrefix: 'components.context',
    });
    const dispatch               = useDispatch();
    const { setSelectedContext } = useMapActions(dispatch);
    const overviewRoutes         = context?.overview?.routes;
    const groupedRoutes          = overviewRoutes.reduce((group, route) => {
        if (!group[route.type]) {
            group[route.type] = [];
        }

        group[route.type].push(route);

        return group;
    }, {});

    function renderRouteGroups() {
        return Object.keys(groupedRoutes).map((routeType) => {
            const groupLabelItems = groupedRoutes[routeType].map((route) => {
                const onLabelClicked = () => {
                    setSelectedContext({
                        id:   route.id,
                        type: ContextType.route,
                    });
                };

                return (
                    {
                        text:      route.shortName,
                        color:     route.color,
                        textColor: route.textColor,
                        onClicked: onLabelClicked,
                    }
                );
            });

            return (
                <ShortNameLabelGroup
                    key={routeType}
                    iconType={routeTypeIconMapping[routeType]}
                    labelItems={groupLabelItems}
                />
            );
        });
    }

    return withContextPanelPadding(<div className={styles.contextOverviewComponent}>
        <UppercaseLabel
            text={t('selectLine')}
        />
        {renderRouteGroups()}
    </div>);
};

ContextOverview.propTypes = propTypes;

export default ContextOverview;
