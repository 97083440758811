//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                  from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector }    from 'react-redux';
import { useDispatch }    from 'react-redux';

import ConnectionDetailType    from '@constants/ConnectionDetailType';
import ContextType             from '@constants/ContextType';
import SearchMode              from '@constants/SearchMode';
import DateTime                from '@helper/DateTime';
import useConnectionsViewApi   from '@hooks/useConnectionsViewApi';
import { useMapActions }       from '@slices/map';
import { useQuickViewActions } from '@slices/quickView';
import IconType                from '@stateless/atomic/Icon/IconType';
import SidebarConnectionItem   from '@stateless/atomic/SidebarConnectionItem';
import SkeletonItem            from '@stateless/atomic/SkeletonItem';
import SkeletonItemType        from '@stateless/atomic/SkeletonItem/SkeletonItemType';
import SidebarStopDetailItem   from '@stateless/composed/SidebarStopDetailItem';

import styles from './styles.module.scss';

const propTypes = {};

const QuickViewContent = () => {
    const searchMode                         = useSelector((state) => state.quickView.searchMode);
    const { data, isFetching, error }        = useConnectionsViewApi();
    const dispatch                           = useDispatch();
    const { setSelectedContext }             = useMapActions(dispatch);
    const { setShowQuickViewSidebarDetails } = useQuickViewActions(dispatch);
    const { t }                              = useTranslation(null, {
        keyPrefix: 'components.action',
    });
    const closeSidebarDetails                = () => {
        setShowQuickViewSidebarDetails({
            showQuickViewSidebarDetails: false,
        });
    };
    const renderConnections                  = () => {
        if (_.isEmpty(data)) {
            return null;
        }

        return _.map(data, (transit, index) => {
            const firstConnection = _.first(transit.connection);
            const firstStopName   = _.get(firstConnection, 'from.nameWithLocation', '');
            const lastConnection  = _.last(transit.connection);
            const lastStopName    = _.get(lastConnection, 'to.nameWithLocation', '');
            const routes          = _.map(transit.connection, (connection) => {
                const toStopId       = connection.to.id;
                const transitTime    = _.find(transit.transitTimes, (transitTimeItem) => {
                    return transitTimeItem.transitStop === toStopId;
                });
                const onRouteClicked = () => {
                    setSelectedContext({
                        id:   connection.routeId,
                        type: ContextType.route,
                    });
                    closeSidebarDetails();
                };

                let changeDetailNumber     = 0;
                let changeDetailRealNumber = 0;

                if (transitTime) {
                    changeDetailNumber     = DateTime.durationStringToMinutes(transitTime.expectedTransitTime);
                    changeDetailRealNumber = DateTime.durationStringToMinutes(transitTime.actualTransitTime);
                }

                return {
                    percentageWidth: 100,
                    name:            _.get(connection, 'line', ''),
                    color:           _.get(connection, 'color', ''),
                    changeStopName:  _.get(connection, 'to.nameWithoutLocation', ''),
                    changeDetailNumber,
                    changeDetailRealNumber,
                    onRouteClicked,
                };
            });

            return (
                <SidebarConnectionItem
                    key={index}
                    fromStopName={firstStopName}
                    toStopName={lastStopName}
                    connectionDetailType={ConnectionDetailType.changeTime}
                    percentageDelay={0} // TODO: https://lulububu.atlassian.net/browse/BODOBIG-909
                    routes={routes}
                />
            );
        });
    };
    const renderStops                        = () => {
        if (_.isEmpty(data)) {
            return null;
        }

        return _.map(data, (stopItem, index) => {
            const stopName          = _.get(stopItem, 'stop.nameWithoutLocation', '');
            const count             = _.get(stopItem, 'count', 0);
            const stopId            = _.get(stopItem, 'stop.id', '');
            const onStopItemClicked = () => {
                setSelectedContext({
                    id:   stopId,
                    type: ContextType.stop,
                });
                closeSidebarDetails();
            };

            return (
                <SidebarStopDetailItem
                    key={index}
                    iconType={IconType.bus}
                    title={stopName}
                    personCount={count}
                    isFavorite={false}
                    onItemClicked={onStopItemClicked}
                />
            );
        });
    };
    const renderLoadingSkeleton              = () => {
        let type = null;

        switch (searchMode) {
            case SearchMode.routes:
                type = SkeletonItemType.connectionItem;

                break;
            case SearchMode.stops:
                type = SkeletonItemType.stopItem;

                break;
        }

        return _.times(
            4,
            () => <SkeletonItem
                type={type}
            />,
        );
    };
    const renderNoResults                    = () => {
        return (
            <div className={styles.textContent}>
                <p>
                    {t('noResults')}
                </p>
            </div>

        );
    };
    const renderErrorMessage                 = () => {
        return (
            <div className={styles.textContent}>
                <p>
                    {t('error')}
                </p>
            </div>
        );
    };

    if (isFetching) {
        return renderLoadingSkeleton();
    }

    if (_.isEmpty(data)) {
        return renderNoResults();
    }

    if (error) {
        return renderErrorMessage();
    }

    if (searchMode === SearchMode.routes) {
        return renderConnections();
    }

    return renderStops();
};

QuickViewContent.propTypes = propTypes;

export default QuickViewContent;
