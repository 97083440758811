//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useCallback } from 'react';
import { useEffect }   from 'react';

import * as turf       from '@turf/turf';
import * as Leaflet    from 'leaflet';
import _               from 'lodash';
import { useMap }      from 'react-leaflet';
import { useDispatch } from 'react-redux';

import PropTypes                 from '@components/PropTypes';
import ContextType               from '@constants/ContextType';
import { useMapActions }         from '@slices/map';
import { useFetchPolylineQuery } from '@store/api/polyline';

// eslint-disable-next-line rulesdir/format-import-linter, import/extensions
import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid.bundled.js';

const propTypes = {
    route: PropTypes.objectOf(PropTypes.any),
};

const MapRouteLayer = ({
    route = null,
}) => {
    const map                    = useMap();
    const dispatch               = useDispatch();
    const { data }               = useFetchPolylineQuery({
        routeId: route.id,
    });
    const { setSelectedContext } = useMapActions(dispatch);
    const onRouteClicked         = useCallback(() => {
        setSelectedContext({
            id:   route.id,
            type: ContextType.route,
        });
    }, [route]);

    useEffect(
        () => {
            if (
                !data ||
                !data.length
            ) {
                return _.noop;
            }

            const lineStrings = turf.lineStrings(data);
            const vectorGrid  = Leaflet.vectorGrid.slicer(lineStrings, {
                rendererFactory:       Leaflet.svg.tile,
                vectorTileLayerStyles: {
                    sliced(properties, zoom) {
                        return {
                            stroke: true,
                            color:  route.color ?? 'lime',
                            weight: 5,
                        };
                    },
                },
                interactive:           true,
                maxZoom:               20,
                minZoom:               14,
            });

            vectorGrid
                .on('click', onRouteClicked)
                .addTo(map);

            return () => {
                map.removeLayer(vectorGrid);
            };
        },
        [],
    );

    return null;
};

MapRouteLayer.propTypes = propTypes;

export default MapRouteLayer;
