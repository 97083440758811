//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import I18n               from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch }    from 'react-redux';
import { useSelector }    from 'react-redux';

import { useUser }  from '@slices/user';
import IconType     from '@stateless/atomic/Icon/IconType';
import DropDownMenu from '@stateless/composed/DropDownMenu';

const UserDropDown = () => {
    // TODO: https://lulububu.atlassian.net/browse/BODOBIG-756
    const dispatch    = useDispatch();
    const userActions = useUser(dispatch);
    const userName    = useSelector((state) => state.user.name);
    const { t }       = useTranslation();

    function onImprintPressed() {
        const imprintUrl = I18n.t('imprintUrl');

        window.open(imprintUrl, '_blank');
    }

    function onLogoutPressed(event) {
        event.preventDefault();
        userActions.logout();
    }

    const menuItems = useMemo(() => [
        {
            label:  t('imprint'),
            icon:   IconType.document,
            action: onImprintPressed,
        },
        {
            label:  t('logout'),
            icon:   IconType.logout,
            action: onLogoutPressed,
        },
    ], []);

    return (
        <DropDownMenu
            iconType={IconType.profile}
            text={userName}
            menuItems={menuItems}
        />
    );
};

export default UserDropDown;
