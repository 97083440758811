//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createApi }      from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import ApiUrls from '@constants/ApiUrls';
import Url     from '@helper/Url';

const CACHING_DURATION_IN_DAYS = 7;

const CACHING_DURATION_IN_SECONDS = CACHING_DURATION_IN_DAYS * 24 * 60 * 60;

export const contextApi = createApi({
    reducerPath:       'contextApi',
    baseQuery:         fetchBaseQuery({
        baseUrl: Url.createEndpoint(ApiUrls.API_CONTEXT_URL),
    }),
    keepUnusedDataFor: CACHING_DURATION_IN_SECONDS,
    endpoints:         (builder) => (
        {
            fetchContext: builder.query({
                query: ({ id, dateRange, type }) => (
                    {
                        url:    '',
                        method: 'POST',
                        body:   {
                            id,
                            dateRange,
                            type,
                        },
                    }
                ),
            }),
        }
    ),
});

export const { useFetchContextQuery } = contextApi;

export const ContextApiReducer = contextApi.reducer;

export const ContextApiReducerPath = contextApi.reducerPath;

export const ContextApiMiddleware = contextApi.middleware;

export default contextApi;
