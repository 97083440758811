//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n               from 'i18next';
import moment             from 'moment';

import BarGraphResolution from '@constants/BarGraphResolution';

class DateTime {
    static getCurrentTime = () => {
        const now         = new Date();
        const currentTime = now.getTime();

        return currentTime;
    };

    static getCurrentTimeInSeconds = () => {
        return DateTime.getCurrentTime() / 1000;
    };

    static getDaysInMilliseconds = (days) => {
        return DateTime.getHoursInMilliseconds(days * 24);
    };

    static getHoursInMilliseconds = (hours) => {
        return DateTime.getMinutesInMilliseconds(hours * 60);
    };

    static getMinutesInMilliseconds = (minutes) => {
        return DateTime.getSecondsInMilliseconds(minutes * 60);
    };

    static getSecondsInMilliseconds = (seconds) => {
        return seconds * 1000;
    };

    static getTimeString = (dateTime, fallbackText) => {
        const messageDate = moment(dateTime);

        if (messageDate.isValid()) {
            const today           = moment();
            const timeStringParts = [];

            if (messageDate.isSame(today, 'day')) {
                timeStringParts.push(I18n.t('today'));
            } else {
                const yesterday = moment().subtract(1, 'day');

                if (messageDate.isSame(yesterday, 'day')) {
                    timeStringParts.push(I18n.t('yesterday'));
                } else {
                    timeStringParts.push(messageDate.format(I18n.t('messageDateFormat')));
                }
            }

            timeStringParts.push(messageDate.format(I18n.t('messageTimeFormat')));
            timeStringParts.push(I18n.t('oclock'));

            const timeString = timeStringParts.join(' ');

            return timeString;
        }

        return fallbackText;
    };

    /**
     * converts duration string (HH:mm:ss) or (HH:mm) to minutes
     *
     * @param duration
     * @returns {number}
     */
    static durationStringToMinutes = (duration) => {
        const durationParts = duration.split(':');
        const hours         = parseInt(durationParts[0], 10);
        const minutes       = parseInt(durationParts[1], 10);

        return hours * 60 + minutes;
    };

    static getTwoHHMMSSDifference(time1, time2) {
        const time1Minutes = this.durationStringToMinutes(time1);
        const time2Minutes = this.durationStringToMinutes(time2);

        return time1Minutes - time2Minutes;
    }

    static getDateTimeStringByResolution(dateTime, resolution) {
        switch (resolution) {
            case BarGraphResolution.hours:
                return moment(dateTime).format('DD.MM.yyyy HH:mm');
            case BarGraphResolution.days:
                return moment(dateTime).format('DD.MM.yyyy');
            case BarGraphResolution.months:
                return moment(dateTime).format('MMMM YYYY');
            default:
                return '';
        }
    }
}

export default DateTime;
