//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { useState }    from 'react';
import { useCallback } from 'react';
import { useMemo }     from 'react';

import classNames      from 'classnames';
import I18n            from 'i18next';
import { useSelector } from 'react-redux';

import MapProvider       from '@components/MapProvider';
import ControlLayer      from '@connected/ControlLayer';
import HomeHeader        from '@connected/HomeHeader';
import MapActionsSidebar from '@connected/MapActionsSidebar';
import MapLayer          from '@connected/MapLayer';
import MapSearchSidebar  from '@connected/MapSearchSidebar';
import SidebarTheme      from '@constants/SidebarTheme';
import PageTitle         from '@stateless/composed/PageTitle';

import styles from './styles.module.scss';

const Home = () => {
    const [mapSearchSidebarExpanded, setMapSearchSidebarExpanded]   = useState(false);
    const [mapActionsSidebarExpanded, setMapActionsSidebarExpanded] = useState(false);
    const onMapActionsSidebarToggleClicked                          = useCallback(
        () => {
            setMapActionsSidebarExpanded(!mapActionsSidebarExpanded);
        },
        [mapActionsSidebarExpanded],
    );
    const onMapSearchSidebarToggleClicked                           = useCallback(
        () => {
            setMapSearchSidebarExpanded(!mapSearchSidebarExpanded);
        },
        [mapSearchSidebarExpanded],
    );
    const isDateRangePickerVisible                                  = useSelector((state) => state.map.showDateRangePicker);
    const mapActionSidebarTheme                                     = useMemo(() => {
        if (
            mapSearchSidebarExpanded ||
            !isDateRangePickerVisible
        ) {
            return SidebarTheme.default;
        }

        return SidebarTheme.lightGray;
    }, [isDateRangePickerVisible, mapActionsSidebarExpanded]);

    return (
        <>
            <PageTitle title={I18n.t('pageHome')} />
            <MapProvider>
                <HomeHeader />
                <MapActionsSidebar
                    onSidebarToggleClicked={onMapActionsSidebarToggleClicked}
                    expanded={mapActionsSidebarExpanded}
                    theme={mapActionSidebarTheme}
                />
                <MapSearchSidebar
                    onSidebarToggleClicked={onMapSearchSidebarToggleClicked}
                    expanded={mapSearchSidebarExpanded}
                />
                <div
                    className={classNames(
                        styles.homeScreen,
                        {
                            [styles.mapActionsSidebarExpanded]: mapActionsSidebarExpanded,
                            [styles.mapSearchSidebarExpanded]:  mapSearchSidebarExpanded,
                        },
                    )}
                >
                    <div className={styles.homeScreenContent}>
                        <ControlLayer />
                        <MapLayer />
                    </div>
                </div>
            </MapProvider>
        </>
    );
};
export default Home;
