//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import _                  from 'lodash';
import { useTranslation } from 'react-i18next';
import { Trans }          from 'react-i18next';
import { useSelector }    from 'react-redux';

import PropTypes               from '@components/PropTypes';
import SelectedDateRangeLabel  from '@connected/SelectedDateRangeLabel';
import ContextType             from '@constants/ContextType';
import DateTime                from '@helper/DateTime';
import withContextPanelPadding from '@hoc/ContextPanelPadding';
import CircleIcon              from '@stateless/atomic/CircleIcon';
import CircleIconTheme         from '@stateless/atomic/CircleIcon/CircleIconTheme';
import ContextSlotLabel        from '@stateless/atomic/ContextSlotLabel';
import IconType                from '@stateless/atomic/Icon/IconType';
import SmallCircle             from '@stateless/atomic/SmallCircle';
import BarGraphSwiper          from '@stateless/composed/BarGraphSwiper';
import ContextTitle            from '@stateless/composed/ContextTitle';
import Colors                  from '@styles/colors.scss';

import styles from './styles.module.scss';

const propTypes = {
    context: PropTypes.object,
};

const ContextVolume = ({
    context = null,
}) => {
    const selectedContext                             = useSelector((state) => state.map.selectedContext);
    const { t }                                       = useTranslation(null, {
        keyPrefix: 'components.context',
    });
    const [inspectedUsagePart, setInspectedUsagePart] = useState(null);
    const selectedContextType                         = selectedContext?.type;
    const resolution                                  = context?.usage?.resolution;
    const slotDateTime                                = inspectedUsagePart?.slot;
    const getSelectedContextIconType                  = () => {
        if (selectedContextType === ContextType.route) {
            return IconType.train;
        }

        return IconType.bus;
    };
    const getAveragePassengerVolumeText               = () => {
        let textKey = 'averagePassengerVolumeStop';

        if (selectedContextType === ContextType.route) {
            textKey = 'averagePassengerVolumeRoute';
        }

        return (
            <Trans
                t={t}
                i18nKey={textKey}
                values={{
                    count: context?.usage?.total,
                }}
            />
        );
    };
    const createUsageBarItems                         = () => {
        const usageItems = context?.usage?.data;

        if (!usageItems) {
            return null;
        }

        let highestTotal = 0;

        _.forEach(usageItems, (usageItem) => {
            const { entered, exited } = usageItem;
            const total               = entered + exited;

            if (total > highestTotal) {
                highestTotal = total;
            }
        });

        return _.map(usageItems, (usageItem) => {
            const { entered, exited, slot } = usageItem;
            const enteredPercentageHeight   = (
                entered / highestTotal
            ) * 100;
            const exitedPercentageHeight    = (
                exited / highestTotal
            ) * 100;

            return {
                barParts:     [
                    {
                        color:  Colors.yellow,
                        height: enteredPercentageHeight,
                    },
                    {
                        color:  Colors.darkAbyss,
                        height: exitedPercentageHeight,
                    },
                ],
                onBarClicked: () => {
                    setInspectedUsagePart(usageItem);
                },
                tooltipText:  DateTime.getDateTimeStringByResolution(slot, resolution),
            };
        });
    };
    const renderDetailItems                           = (items) => {
        return _.map(items, (item) => {
            return (
                <div
                    key={item.text}
                    className={styles.volumePartInformationItem}
                >
                    <SmallCircle color={item.color} />
                    <span>
                        {item.text}
                    </span>
                </div>
            );
        });
    };
    const renderUsagePartDetails                      = () => {
        if (!inspectedUsagePart) {
            return null;
        }

        const { exited, entered } = inspectedUsagePart;
        const total               = entered + exited;
        const detailItems         = [
            {
                color: Colors.blue,
                text:  `${total} ${t('usageTotal')}`,
            },
            {
                color: Colors.yellow,
                text:  `${entered} ${t('usageEntered')}`,
            },
            {
                color: Colors.darkAbyss,
                text:  `${exited} ${t('usageExited')}`,
            },
        ];

        return (
            <div className={styles.volumePartInformation}>
                <ContextSlotLabel
                    resolution={resolution}
                    slotDateTime={slotDateTime}
                />
                <div>
                    {renderDetailItems(detailItems)}
                </div>
            </div>
        );
    };

    return withContextPanelPadding(<div className={styles.contextVolumeComponent}>
        <div className={styles.headerRow}>
            <ContextTitle
                text={t('passengerVolume')}
            />
            <SelectedDateRangeLabel />
        </div>
        <div className={styles.contentRow}>
            <CircleIcon
                circleIconTheme={CircleIconTheme.yellow}
                iconType={getSelectedContextIconType()}
            />
            <span>
                {getAveragePassengerVolumeText()}
            </span>
        </div>
        <div className={styles.volumeSwiperWrapper}>
            <BarGraphSwiper barItems={createUsageBarItems()} />
        </div>
        {renderUsagePartDetails()}
    </div>);
};

ContextVolume.propTypes = propTypes;

export default ContextVolume;
