//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

import { DateRangeDefault } from '@helper/DateRange';

const initialState = Object.freeze({
    coordinates:         {
        latitude:  47.779078,
        longitude: 9.603552,
    },
    zoom:                15,
    selectedContext:     {
        id:   null,
        type: null,
    },
    showDateRangePicker: false,
    selectedDateRange:   DateRangeDefault,
});

const mapSlice = createSlice({
    name:     'map',
    initialState,
    reducers: {
        setCoordinates:         (state, action) => {
            const { latitude, longitude, bounds } = action.payload;

            return update(state, {
                coordinates: {
                    $set: {
                        latitude,
                        longitude,
                    },
                },
                bounds:      {
                    $set: bounds,
                },
            });
        },
        setZoom:                (state, action) => {
            const { zoom, bounds } = action.payload;

            return update(state, {
                zoom:   {
                    $set: zoom,
                },
                bounds: {
                    $set: bounds,
                },
            });
        },
        setSelectedContext:     (state, action) => {
            const { id, type } = action.payload;

            return update(state, {
                selectedContext: {
                    $set: {
                        id,
                        type,
                    },
                },
            });
        },
        setShowDateRangePicker: (state, action) => {
            const { show } = action.payload;

            return update(state, {
                showDateRangePicker: {
                    $set: show,
                },
            });
        },
        setSelectedDateRange:   (state, action) => {
            const { from, to } = action.payload;

            return update(state, {
                selectedDateRange: {
                    $set: {
                        from,
                        to,
                    },
                },
            });
        },
    },
});

export const MapActions = mapSlice.actions;

export const MapReducer = mapSlice.reducer;

export const useMapActions = (dispatch) => bindActionCreators(MapActions, dispatch);

export default mapSlice;
