//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { useState }    from 'react';
import { useCallback } from 'react';

import I18n               from 'i18next';
import _                  from 'lodash';
import { useTranslation } from 'react-i18next';

import PropTypes               from '@components/PropTypes';
import SelectedDateRangeLabel  from '@connected/SelectedDateRangeLabel';
import DateTime                from '@helper/DateTime';
import withContextPanelPadding from '@hoc/ContextPanelPadding';
import ContextSlotLabel        from '@stateless/atomic/ContextSlotLabel';
import UppercaseLabel          from '@stateless/atomic/UppercaseLabel';
import BarGraphSwiper          from '@stateless/composed/BarGraphSwiper';
import ContextTitle            from '@stateless/composed/ContextTitle';
import Colors                  from '@styles/colors.scss';

import styles from './styles.module.scss';

const propTypes = {
    context: PropTypes.object,
};

const ContextPunctuality = ({
    context = null,
}) => {
    const { t }                                                   = useTranslation(null, {
        keyPrefix: 'components.context',
    });
    const [inspectedPunctualityPart, setInspectedPunctualityPart] = useState(null);
    const resolution                                              = context?.punctuality?.resolution;
    const slotDateTime                                            = inspectedPunctualityPart?.slot;
    const createPunctualityBarItems                               = () => {
        const punctualityItems = context?.punctuality?.data;

        if (!punctualityItems) {
            return null;
        }

        return _.map(punctualityItems, (punctualityItem) => {
            return {
                barParts:     [
                    {
                        color:  Colors.red,
                        height: punctualityItem.percentageLate,
                    },
                ],
                onBarClicked: () => {
                    setInspectedPunctualityPart(punctualityItem);
                },
                tooltipText:  DateTime.getDateTimeStringByResolution(punctualityItem.slot, resolution),
            };
        });
    };
    const renderDetailParts                                       = useCallback(() => {
        const detailParts = [
            {
                text:  t('rides'),
                value: inspectedPunctualityPart.usage,
            },
            {
                text:      t('delay'),
                value:     `${inspectedPunctualityPart.delay} ${I18n.t('minutesShort')} ${t('percentualDelay', {
                    percentage: inspectedPunctualityPart.percentageLate,
                })}`,
                className: styles.delay,
            },
        ];

        return _.map(detailParts, (detailPart) => {
            return (
                <div key={detailPart.text}>
                    <UppercaseLabel text={detailPart.text} />
                    <span className={detailPart.className}>
                        {detailPart.value}
                    </span>
                </div>
            );
        });
    }, [inspectedPunctualityPart]);
    const renderPunctualityPartDetails                            = () => {
        if (!inspectedPunctualityPart) {
            return null;
        }

        return (
            <div className={styles.punctualityDetailsWrapper}>
                <ContextSlotLabel
                    resolution={resolution}
                    slotDateTime={slotDateTime}
                />
                <div>
                    {renderDetailParts()}
                </div>
            </div>
        );
    };

    return (
        withContextPanelPadding(<div className={styles.contextPunctualityComponent}>
            <div className={styles.headerRow}>
                <ContextTitle
                    text={t('punctuality')}
                />
                <SelectedDateRangeLabel />
            </div>
            <div className={styles.punctualitySwiperWrapper}>
                <BarGraphSwiper barItems={createPunctualityBarItems()} />
            </div>
            {renderPunctualityPartDetails()}
        </div>)
    );
};

ContextPunctuality.propTypes = propTypes;

export default ContextPunctuality;
